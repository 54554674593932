/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgHealthInsurance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgHealthInsurance"
    {...props}
  >
    <path
      d="M3.5 5.5v-4H5M7.5 5.5v-4H6M5.5 11.5v-4M7.5 5.5a2 2 0 1 1-4 0M5.5 11.5a2 2 0 0 0 2 2M7.5 13.5a2 2 0 0 0 2-2v-3M9.489 8.5A2 2 0 0 1 11.5 6.511"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle cx={12.5} cy={6.5} r={1} fill="none" stroke="#000" strokeMiterlimit="10" />
  </svg>
)
