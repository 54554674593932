/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPin01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgPin01Solid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M8.324 1c-.362 0-.698 0-.973.025-.288.026-.647.088-.98.308a2 2 0 0 0-.852 1.26c-.081.39-.005.746.077 1.023.08.265.204.577.338.913l1.023 2.556c.014.035.035.073.042.11v2.243q.001.105 0 .21l-.003.003-.128.163-1.597 1.996c-.31.387-.583.729-.775 1.019-.187.283-.415.684-.415 1.169a2 2 0 0 0 .752 1.565c.379.302.834.375 1.172.406.347.031.785.031 1.28.031H11v6a1 1 0 0 0 2 0v-6h3.715c.495 0 .933 0 1.28-.031.338-.031.793-.104 1.172-.406a2 2 0 0 0 .752-1.565c0-.485-.228-.886-.416-1.169-.191-.29-.465-.632-.774-1.018l-1.598-1.997q-.066-.082-.13-.165v-.003L17 9.439V7.193l.043-.108 1.022-2.556c.135-.336.26-.648.339-.913.082-.277.158-.633.077-1.023a2 2 0 0 0-.853-1.26c-.332-.22-.691-.282-.979-.308C16.374 1 16.038 1 15.676 1z"
      clipRule="evenodd"
    />
  </svg>
)
