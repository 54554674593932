/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDigitsWordmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 231 100"
    aria-label="SvgDigitsWordmark"
    {...props}
  >
    <g fill="#000">
      <path d="M7.909 19.3h22.95c18.626 0 31.073 11.575 31.073 28.935S49.485 77.17 30.859 77.17H7.91zm22.773 51.34c13.418 0 22.334-8.928 22.334-22.405S44.1 25.831 30.682 25.831H16.56v44.807h14.123zM72.858 17.647c3.354 0 5.914 2.48 5.914 5.623 0 3.141-2.56 5.622-5.914 5.622s-6.002-2.398-6.002-5.623 2.56-5.622 6.002-5.622m-4.237 18.27h8.385V77.17h-8.384zM85.5 78.328l8.21-.331c.883 5.869 6.268 9.837 13.681 9.837 8.21 0 13.595-4.961 13.595-13.062v-7.937c-2.471 5.208-8.562 9.093-15.712 9.093-11.83 0-21.185-8.764-21.185-20.667s9.18-20.585 21.185-20.585c7.15 0 13.592 3.72 16.066 9.342v-8.1h7.944v37.615c0 12.896-8.473 20.585-21.891 20.585-12.358 0-21.008-6.367-21.89-15.79zm21.539-8.845c8.122 0 14.475-5.951 14.475-14.218s-6.002-14.138-14.475-14.138-14.563 6.2-14.563 14.138 6.356 14.218 14.563 14.218M142.298 17.647c3.354 0 5.913 2.48 5.913 5.623 0 3.141-2.559 5.622-5.913 5.622s-6.002-2.398-6.002-5.623 2.559-5.622 6.002-5.622m-4.237 18.27h8.385V77.17h-8.385zM162.484 64.44V42.45h-8.473v-6.533h8.473V25.584h8.385v10.333h11.386v6.532h-11.386v21.412c0 7.276 5.207 8.02 10.064 6.367l.177 6.53c-8.562 2.563-18.626.412-18.626-12.319M187.179 64.108l7.767-.25c.529 5.623 5.208 9.177 11.653 9.177 5.208 0 9.71-2.232 9.71-6.53 0-3.224-2.648-5.042-7.856-6.034l-7.502-1.322c-8.562-1.323-12.535-5.623-12.535-11.658 0-8.02 7.15-13.062 16.948-13.062s17.566 5.786 18.006 14.384l-7.501.413c-.354-5.374-4.68-9.177-10.593-9.177-4.942 0-8.916 2.564-8.916 6.696 0 3.059 2.208 4.961 6.179 5.623l7.505 1.322c9.267 1.57 14.3 5.54 14.3 12.069 0 7.937-7.59 12.814-17.743 12.814-11.035 0-18.714-5.623-19.419-14.468z" />
    </g>
  </svg>
)
