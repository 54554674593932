/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDeleteSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgDeleteSolid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M8.176 3.115C8.584 3 9.013 3 9.5 3h7.742c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392a4 4 0 0 1 1.748 1.748c.247.486.346 1.002.392 1.564C23 7.29 23 7.954 23 8.758v6.484c0 .804 0 1.469-.044 2.01-.046.562-.145 1.079-.392 1.564a4 4 0 0 1-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H9.498c-.486 0-.915.001-1.323-.115a3 3 0 0 1-.99-.495c-.337-.256-.594-.6-.886-.99l-4.38-5.84-.055-.073c-.198-.262-.447-.59-.55-.978a2 2 0 0 1 0-1.018c.103-.387.352-.716.55-.978l.055-.073L6.3 4.6c.292-.39.549-.733.887-.99a3 3 0 0 1 .99-.495m9.531 5.178a1 1 0 0 1 0 1.414L15.414 12l2.293 2.293a1 1 0 0 1-1.414 1.414L14 13.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L12.586 12l-2.293-2.293a1 1 0 1 1 1.414-1.414L14 10.586l2.293-2.293a1 1 0 0 1 1.414 0"
      clipRule="evenodd"
    />
  </svg>
)
