/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBankSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgBankSolid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M11.825 2.032a2 2 0 0 1 .35 0c.133.012.261.04.362.063l7.454 1.656c.2.045.4.09.568.14.188.056.402.138.61.288a2 2 0 0 1 .669.834c.101.236.135.463.15.658.012.176.012.38.012.585v1.176c0 .252 0 .498-.017.706a2 2 0 0 1-.201.77 2 2 0 0 1-.874.874 2 2 0 0 1-.77.201q-.066.006-.138.009v6.016q.072.004.138.009c.229.019.499.063.77.201a2 2 0 0 1 .874.874c.138.271.182.541.201.77.017.208.017.454.017.706v.864c0 .252 0 .498-.017.706a2 2 0 0 1-.201.77 2 2 0 0 1-.874.874 2 2 0 0 1-.77.201c-.208.017-.454.017-.706.017H4.568c-.252 0-.498 0-.706-.017a2 2 0 0 1-.77-.201 2 2 0 0 1-.874-.874 2 2 0 0 1-.201-.77C2 19.93 2 19.684 2 19.432v-.864c0-.252 0-.498.017-.706.019-.229.063-.499.201-.77a2 2 0 0 1 .874-.874 2 2 0 0 1 .77-.201q.066-.005.138-.009V9.992l-.138-.009a2 2 0 0 1-.77-.201 2 2 0 0 1-.874-.874 2 2 0 0 1-.201-.77C2 7.93 2 7.684 2 7.432V6.256c0-.205 0-.41.013-.585.014-.195.048-.422.15-.658a2 2 0 0 1 .668-.834c.208-.15.422-.232.61-.288.169-.05.368-.095.568-.14l7.454-1.656c.101-.023.229-.051.362-.063M6 10v6h2.5v-6zm4.5 0v6h3v-6zm5 0v6H18v-6z"
      clipRule="evenodd"
    />
  </svg>
)
