/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSales = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgSales"
    {...props}
  >
    <path d="M1 .992v1h2c.215 0 .398.14.469.344l.933 8.332A1.51 1.51 0 0 0 5.895 12H12v-1H5.895a.493.493 0 0 1-.497-.441L5.336 10h6.344c.71 0 1.332-.508 1.472-1.203L14.11 4H4.66l-.215-1.906-.015-.047A1.5 1.5 0 0 0 3 .992ZM11 12c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1m-5 0c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1M4.773 5h8.118l-.72 3.598A.5.5 0 0 1 11.68 9H5.223Z" />
  </svg>
)
