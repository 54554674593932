/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgOpeningBalanceEquity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgOpeningBalanceEquity"
    {...props}
  >
    <path
      d="M12.5 2.5h-9a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1zM8 10.5h4M4.5 6.5h7M9.5 4.5v4"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M4.5 4.5h7v4h-7z"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
  </svg>
)
