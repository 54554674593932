/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSecurityBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 25 30"
    aria-label="SvgSecurityBadge"
    {...props}
  >
    <path
      fill="url(#security-badge_svg__a)"
      d="M11.472 29.57c.329.192.493.288.725.337.18.039.442.039.622 0 .232-.05.396-.145.725-.337 2.892-1.687 10.84-6.986 10.84-14.272v-7.1c0-1.186 0-1.78-.195-2.29a3 3 0 0 0-.812-1.171c-.41-.36-.965-.57-2.076-.986L13.34.766c-.308-.115-.462-.173-.62-.196q-.213-.03-.425 0c-.16.023-.313.08-.622.196L3.715 3.751c-1.111.417-1.667.625-2.076.986-.362.319-.64.72-.812 1.171-.194.51-.194 1.104-.194 2.29v7.1c0 7.286 7.947 12.585 10.839 14.272"
    />
    <mask id="security-badge_svg__b" width={25} height={30} x={0} y={0} maskUnits="userSpaceOnUse">
      <path
        fill="#fff"
        fillOpacity={0.3}
        d="M11.472 29.572c.329.191.493.287.725.337.18.039.443.039.623 0 .231-.05.396-.146.724-.337 2.892-1.687 10.84-6.987 10.84-14.273v-7.1c0-1.186 0-1.78-.195-2.29a3 3 0 0 0-.811-1.17c-.41-.362-.965-.57-2.077-.987L13.342.767c-.308-.115-.463-.173-.622-.196q-.211-.03-.424 0c-.159.023-.313.08-.622.196L3.715 3.752c-1.111.417-1.667.625-2.076.986-.362.319-.64.72-.812 1.172C.633 6.42.633 7.013.633 8.2v7.099c0 7.286 7.947 12.586 10.84 14.273"
      />
    </mask>
    <g mask="url(#security-badge_svg__b)">
      <path
        fill="url(#security-badge_svg__c)"
        d="M12.352.132h15.743v35.694H12.352z"
        opacity={0.5}
      />
    </g>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.258 11.969v-1.5a3.75 3.75 0 1 0-7.5 0v1.5m3.75 3.375v1.5m-2.4 3.375h4.8c1.26 0 1.89 0 2.371-.245a2.25 2.25 0 0 0 .984-.984c.245-.481.245-1.111.245-2.371v-1.05c0-1.26 0-1.89-.245-2.372a2.25 2.25 0 0 0-.984-.983c-.481-.245-1.111-.245-2.371-.245h-4.8c-1.26 0-1.89 0-2.372.245a2.25 2.25 0 0 0-.983.983c-.245.482-.245 1.112-.245 2.372v1.05c0 1.26 0 1.89.245 2.371.216.424.56.768.983.984.482.245 1.112.245 2.372.245"
    />
    <defs>
      <linearGradient
        id="security-badge_svg__a"
        x1={0.3}
        x2={26.007}
        y1={39.103}
        y2={37.059}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C92BE" stopOpacity={0.8} />
        <stop offset={1} stopColor="#3D3AC2" stopOpacity={0.54} />
      </linearGradient>
      <linearGradient
        id="security-badge_svg__c"
        x1={20.223}
        x2={20.223}
        y1={1.307}
        y2={28.81}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.6} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
