/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgArrowBlockDownSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgArrowBlockDownSolid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M9.777 2h4.446c.12 0 .261 0 .387.01.145.012.353.042.571.153a1.5 1.5 0 0 1 .656.656c.11.218.141.426.153.571.01.125.01.268.01.387V13h3a1 1 0 0 1 .707 1.707l-7 7a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 5 13h3V3.777c0-.12 0-.262.01-.387.012-.145.042-.353.154-.571a1.5 1.5 0 0 1 .655-.656c.218-.11.426-.141.571-.153.126-.01.268-.01.387-.01"
      clipRule="evenodd"
    />
  </svg>
)
