/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCustomerSupport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgCustomerSupport"
    {...props}
  >
    <path
      d="M4.5 14v-2.125c0-1.934-2-2.578-2-5.375 0-2.75 1.872-5 5-5 3.206 0 4.519 2.077 4.83 4.219a1 1 0 0 0 .109.329l.988 1.771a.58.58 0 0 1-.284.818l-.643.238V10.5c0 2-2 2-2 2V14"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle cx={10} cy={10} r={1} />
    <path d="M6.5 5.875V1.574M8 8l2 2" fill="none" stroke="#000" strokeMiterlimit="10" />
    <circle cx={7} cy={7} r={1.5} fill="none" stroke="#000" strokeMiterlimit="10" />
  </svg>
)
