/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgInventoryAdjustments = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgInventoryAdjustments"
    {...props}
  >
    <path
      d="M4.5 4.5h7v7h-7zM7.5 6.5h1"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path d="M3 13 .5 10.5 3 8zM13 8l2.5-2.5L13 3z" />
    <path
      d="M3 5.5H2M1 5.5H0M14 10.5h-1M16 10.5h-1"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
