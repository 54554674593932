/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMessageDotsCircleSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgMessageDotsCircleSolid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12c0 1.328.26 2.599.732 3.76l.084.211.005.014-.001.005-.03.183-.597 3.587c-.027.16-.057.34-.069.496-.013.17-.017.44.106.727a1.5 1.5 0 0 0 .787.787c.286.123.557.118.727.106.156-.012.336-.042.496-.069l3.587-.598.183-.029h.005l.014.005c.045.016.105.04.21.083A10 10 0 0 0 12 22c5.523 0 10-4.477 10-10S17.523 2 12 2M6 12a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0m4.5 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0m6-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"
      clipRule="evenodd"
    />
  </svg>
)
