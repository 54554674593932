/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgUncategorizedExpenses = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgUncategorizedExpenses"
    {...props}
  >
    <path d="M3 2.102V7h-.5C1.602 7 1 7.602 1 8.5v4c0 .898.602 1.5 1.5 1.5h10c.898 0 1.5-.602 1.5-1.5v-4c0-.898-.602-1.5-1.5-1.5H12V2.102L10.5 3l-1-.602L8.5 3l-1-.602L6.5 3l-1-.602L4.5 3Zm2.5 1.5 1 .597 1-.597 1 .597 1-.597 1 .597.5-.3V9H4V3.898l.5.301ZM2.5 8H3v2h9V8h.5c.3 0 .5.2.5.5v4c0 .3-.2.5-.5.5h-10c-.3 0-.5-.2-.5-.5v-4c0-.3.2-.5.5-.5M4 11v1h1v-1Zm2 0v1h1v-1Zm2 0v1h1v-1Zm2 0v1h1v-1Z" />
  </svg>
)
