/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgClearingAccount = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgClearingAccount"
    {...props}
  >
    <path d="M7.5 2.5v3" fill="none" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" />
    <path d="M7.5 1.5 5 4h5z" />
    <circle
      cx={7.5}
      cy={9.5}
      r={2}
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
    <path
      d="M1.5 6v6.5a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V6M7.5 10.5v-2"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
