/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgGrossWages = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgGrossWages"
    {...props}
  >
    <path
      d="M7.5 9.375 1.625 5.5H1.5v7a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-7h-.125z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M10.583 3.623 13.5 5.25v1.125M1.5 7V5.25l2.914-1.625M10.5 7.5v-4a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v3.75"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M5.5 3a.5.5 0 0 1-.5.5M9.5 3a.5.5 0 0 0 .5.5"
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
    <circle cx={7.5} cy={4.5} r={0.5} />
    <path d="M7.5 7A1.5 1.5 0 0 0 6 8.5l1.5.5L9 8.5A1.5 1.5 0 0 0 7.5 7" />
  </svg>
)
