/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMealsEntertainment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="-0.5 -0.5 16 16"
    aria-label="SvgMealsEntertainment"
    {...props}
  >
    <path d="M1 2v4.5c0 1.758 1.309 3.203 3 3.45V13H2v1h5v-1H5V9.95c1.691-.247 3-1.692 3-3.45V2Zm8 0v4.5c0 1.203.863 2.215 2 2.45V14h1V8.95a2.51 2.51 0 0 0 2-2.45V2h-1v4.5c0 .836-.664 1.5-1.5 1.5A1.49 1.49 0 0 1 10 6.5V2Zm2 0v4.5c0 .277.223.5.5.5s.5-.223.5-.5V2ZM2 3h5v3.5C7 7.887 5.887 9 4.5 9A2.49 2.49 0 0 1 2 6.5Z" />
  </svg>
)
