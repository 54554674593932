/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgInterest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgInterest"
    {...props}
  >
    <path d="m12.625 3.375-9.25 9.25" fill="none" stroke="#000" strokeMiterlimit="10" />
    <circle cx={5} cy={5} r={1.5} fill="none" stroke="#000" strokeMiterlimit="10" />
    <circle cx={11} cy={11} r={1.5} fill="none" stroke="#000" strokeMiterlimit="10" />
  </svg>
)
