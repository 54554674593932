/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgGifts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgGifts"
    {...props}
  >
    <path
      d="M1.5 12.707V6.292c0-.437.448-.792 1-.792h10c.552 0 1 .354 1 .792v6.416c0 .437-.448.792-1 .792h-10c-.552-.001-1-.355-1-.793zM1.5 9.5h12M7.5 5.501V13.5"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M10.207 4.207c-.39.39-2.832 1.293-2.832 1.293s1.028-2.317 1.418-2.707a1 1 0 0 1 1.414 1.414z"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      d="M6.207 2.793c.39.39 1.418 2.707 1.418 2.707s-2.442-.903-2.832-1.293a1 1 0 0 1 1.414-1.414z"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
  </svg>
)
