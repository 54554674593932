/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgOutbound = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgOutbound"
    {...props}
  >
    <path
      d="M13.5 11v1.5a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1V4M5 7.5h8.5"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path d="M10.375 4.375 13.5 7.5l-3.125 3.125" fill="none" stroke="#000" strokeMiterlimit="10" />
  </svg>
)
