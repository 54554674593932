/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgStar03Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgStar03Solid"
    {...props}
  >
    <path
      fill="#000"
      d="M12.634 1.725a1.5 1.5 0 0 0-1.268 0c-.344.16-.54.438-.64.595-.102.159-.207.36-.307.555l-1.46 2.81-3.107-.61a7 7 0 0 0-.626-.106c-.185-.02-.523-.04-.864.129a1.5 1.5 0 0 0-.79.992c-.09.369.006.694.066.87.06.18.153.387.242.587l1.286 2.893-2.414 2.049c-.167.141-.34.288-.473.423-.13.133-.357.385-.437.756a1.5 1.5 0 0 0 .282 1.237c.233.3.547.428.722.49.178.065.398.122.61.177l3.064.798.096 3.165c.007.219.013.446.036.633.023.185.078.52.318.814.281.343.7.545 1.144.55.38.005.675-.16.834-.258.16-.099.343-.235.518-.366L12 19.009l2.534 1.898c.176.132.357.268.518.367.159.098.455.263.835.258a1.5 1.5 0 0 0 1.143-.55c.24-.294.295-.629.318-.814.023-.187.03-.414.036-.633l.096-3.165 3.065-.798c.211-.055.431-.112.609-.176.175-.063.489-.191.722-.491a1.5 1.5 0 0 0 .282-1.237c-.08-.371-.307-.623-.437-.756-.132-.135-.306-.282-.473-.423l-2.414-2.049 1.286-2.893c.09-.2.182-.408.242-.586.06-.177.156-.502.067-.871a1.5 1.5 0 0 0-.791-.992c-.34-.17-.679-.149-.864-.13-.188.02-.411.064-.626.106l-3.107.61-1.46-2.81a7 7 0 0 0-.307-.554c-.1-.157-.295-.434-.64-.595"
    />
  </svg>
)
