/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgQualityControlMissingCategory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 30 30"
    aria-label="SvgQualityControlMissingCategory"
    {...props}
  >
    <filter
      id="quality-control-missing-category_svg__a"
      width={23.533}
      height={14}
      x={4}
      y={9}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_19044_251552" />
      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_19044_251552" result="shape" />
    </filter>
    <g filter="url(#quality-control-missing-category_svg__a)">
      <path
        fill="#00a69c"
        fillRule="evenodd"
        d="M25.203 16.434A5.5 5.5 0 0 0 16.207 22H5.803c-.51-.001-.935-.568-.765-1.135l2.465-10.11c.085-.473.425-.756.765-.756H25.73c.51 0 .935.567.765 1.134z"
        clipRule="evenodd"
      />
    </g>
    <path
      fill="#00a69c"
      fillOpacity={0.3}
      fillRule="evenodd"
      d="M12.524 7.066h10.471c.502 0 .92.47.836 1.033v7.418A5.5 5.5 0 0 0 16.207 22H5.836C5.418 22 5 21.624 5 21.06V5.94c0-.47.418-.94.92-.94h4.18c.166 0 .417.094.584.282l1.17 1.503a.9.9 0 0 0 .67.281"
      clipRule="evenodd"
    />
    <circle cx={21.5} cy={20.5} r={4.5} fill="#00a69c" transform="rotate(-90 21.5 20.5)" />
    <path
      fill="#c8fbfc"
      d="M21.023 21.06q0-.206.05-.348a.7.7 0 0 1 .171-.267q.177-.185.322-.32.152-.138.26-.253.106-.123.163-.226a.45.45 0 0 0 .064-.225.45.45 0 0 0-.455-.478q-.297 0-.455.177a.73.73 0 0 0-.17.43L20 19.482q.069-.724.493-1.1.423-.382 1.086-.382.291 0 .543.089.259.082.455.252.195.17.31.424c.114.254.113.362.113.58q0 .199-.038.363a1.1 1.1 0 0 1-.133.314 1.7 1.7 0 0 1-.24.32q-.144.159-.372.35a.7.7 0 0 0-.196.238.6.6 0 0 0-.05.233v.198h-.948zm-.107 1.312q0-.13.044-.246c.044-.116.07-.144.12-.198a.57.57 0 0 1 .417-.185q.12 0 .227.048a.4.4 0 0 1 .183.137q.082.081.127.198c.045.117.044.16.044.246s-.015.168-.044.245-.072.146-.127.205a.55.55 0 0 1-.41.178q-.12 0-.227-.048a.5.5 0 0 1-.19-.13.7.7 0 0 1-.164-.45"
    />
  </svg>
)
