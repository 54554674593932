/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgGroundTransportationParking = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgGroundTransportationParking"
    {...props}
  >
    <path
      d="M7.992 2a.5.5 0 0 0-.18.037l-6.5 2.625A.5.5 0 0 0 1 5.125V14h1V5.463l6-2.424 6 2.424V14h1V5.125a.5.5 0 0 0-.312-.463l-6.5-2.625A.5.5 0 0 0 7.992 2M5.605 6.027c-.246 0-.505.109-.67.268a1.4 1.4 0 0 0-.326.514l-.011.027-.623 2.24c-.076.027-.16.05-.33.139-.117.06-.242.131-.366.244-.123.113-.279.293-.279.578V13.5a.5.5 0 0 0 .5.5H4v-1h8v1h.5a.5.5 0 0 0 .5-.5v-3.463c0-.285-.156-.465-.28-.578a1.6 1.6 0 0 0-.365-.244c-.105-.055-.124-.054-.205-.086l-.814-2.322-.012-.026a1.7 1.7 0 0 0-.3-.459 1 1 0 0 0-.684-.295zm.016 1h4.688c.017.02.06.086.107.18L11.045 9H5.033l.508-1.822c.04-.09.072-.14.08-.15M4.348 10h7.304c.043.015.127.042.243.102.049.025.076.045.105.066V12h-1v-1h-1v1H6v-1H5v1H4v-1.832c.029-.02.056-.04.105-.066.116-.06.2-.087.243-.102"
      fontFamily="sans-serif"
      fontWeight={400}
      overflow="visible"
    />
  </svg>
)
