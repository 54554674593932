/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgNoConnection = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 17 17"
    aria-label="SvgNoConnection"
    {...props}
  >
    <path
      fill="#32497F"
      d="M6.2 5.355a.5.5 0 1 0 .667-.745zm-.441 4.719.372.333a.5.5 0 0 0 .039-.618zm1.244 1.114.238-.44a.5.5 0 0 0-.61.106zm5.309-1.704a.5.5 0 0 0-.668.745zm-4.508-5.92c-.493-.44-1.264-.434-1.719.075l.745.667c.062-.07.197-.095.307.003zm-1.719.075c-.455.508-.377 1.275.115 1.716l.667-.745c-.109-.098-.099-.235-.037-.304zM5.386 9.74l-2.61 2.916.745.667 2.61-2.916zm-2.61 2.916a.704.704 0 0 0 .06.994l.668-.745c.106.094.14.28.017.418zm.06.994.935.836.667-.745-.934-.836zm.935.836a.704.704 0 0 0 .994-.05l-.745-.666a.296.296 0 0 1 .418-.03zm.994-.05 2.61-2.915-.745-.667-2.61 2.916zm6.88-4.206c.492.44 1.263.434 1.718-.075l-.745-.667c-.062.07-.197.094-.306-.004zm1.718-.075c.456-.509.377-1.276-.115-1.716l-.667.745c.11.097.1.235.037.304zm-6.598 1.472c1.438.778 3.193.604 4.386-.448l-.661-.75c-.864.762-2.163.907-3.25.319zM5.28 5.994c-.883 1.297-.853 3.036.068 4.365l.822-.57c-.696-1.004-.703-2.293-.063-3.233zm7.968 2.445L7.804 3.564l-.667.745 5.444 4.875z"
    />
    <path
      stroke="#32497F"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m8.883 4.954 2.574-2.874M11.816 7.468l2.574-2.875M14.41 14.016 2.41 3.35"
    />
  </svg>
)
