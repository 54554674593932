/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDetectedInputStars = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 16 16"
    aria-label="SvgDetectedInputStars"
    {...props}
  >
    <path
      fill="url(#detected-input-stars_svg__a)"
      d="M10.622 1.094a.667.667 0 0 0-1.245 0l-.786 2.043c-.2.52-.263.67-.349.792a1.3 1.3 0 0 1-.314.314c-.12.086-.271.149-.792.349l-2.043.786a.667.667 0 0 0 0 1.244l2.043.786c.521.2.671.263.792.35.122.085.228.192.314.313s.15.271.35.792l.785 2.043a.667.667 0 0 0 1.245 0l.785-2.043c.2-.52.264-.67.35-.792q.13-.183.314-.314c.12-.086.27-.149.791-.35l2.043-.785a.667.667 0 0 0 0-1.244l-2.043-.786c-.52-.2-.67-.263-.791-.35a1.3 1.3 0 0 1-.314-.313c-.086-.121-.15-.272-.35-.792z"
    />
    <path
      fill="url(#detected-input-stars_svg__b)"
      d="M4.929 8.368a.667.667 0 0 0-1.193 0l-.523 1.046c-.188.377-.245.485-.315.576q-.105.136-.242.242c-.09.07-.199.127-.576.315l-1.045.523a.667.667 0 0 0 0 1.193l1.045.523c.377.188.486.245.576.315q.136.105.242.242c.07.09.127.2.315.576l.523 1.046a.667.667 0 0 0 1.193 0l.523-1.046c.188-.377.246-.485.315-.576q.106-.136.242-.242c.09-.07.2-.127.576-.315l1.046-.523a.667.667 0 0 0 0-1.193l-1.046-.523c-.377-.188-.485-.245-.576-.315a1.3 1.3 0 0 1-.242-.242c-.07-.09-.127-.199-.315-.576z"
    />
    <defs>
      <linearGradient
        id="detected-input-stars_svg__a"
        x1={0.46}
        x2={16.283}
        y1={19.909}
        y2={18.353}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C92BE" stopOpacity={0.8} />
        <stop offset={1} stopColor="#3D3AC2" stopOpacity={0.54} />
      </linearGradient>
      <linearGradient
        id="detected-input-stars_svg__b"
        x1={0.46}
        x2={16.283}
        y1={19.909}
        y2={18.353}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C92BE" stopOpacity={0.8} />
        <stop offset={1} stopColor="#3D3AC2" stopOpacity={0.54} />
      </linearGradient>
    </defs>
  </svg>
)
