/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgProductDevelopment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgProductDevelopment"
    {...props}
  >
    <path
      d="M1.499 4.5v-2a1 1 0 0 1 1-1H12.5a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2.499a1 1 0 0 1-1-1v-8M6 6.5h3M1.499 4.5H13.5"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
