/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSecurityDeposits = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgSecurityDeposits"
    {...props}
  >
    <path d="M10 13h2v1h-2zM3 12.75h2V14H3z" />
    <path
      d="M14.5 4v2M14.5 9v2M1.5 6V3.5a1 1 0 0 1 1-1h6M8.5 12.5h-6a1 1 0 0 1-1-1V9"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="m12.664 12.67-3.932.827a.2.2 0 0 1-.232-.198V1.701a.2.2 0 0 1 .233-.198l3.932.827c.482.081.835.5.835.992v8.356c0 .492-.353.911-.836.992z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <ellipse cx={11} cy={7.5} rx={0.5} ry={2} fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M5.5 7.5h-4" fill="none" stroke="#000" strokeLinecap="square" strokeMiterlimit="10" />
    <path d="m4 5 2.5 2.5L4 10z" />
  </svg>
)
