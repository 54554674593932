/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMoneyBag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgMoneyBag"
    {...props}
  >
    <g stroke="#000" strokeWidth={1.5}>
      <path
        strokeLinejoin="round"
        d="M20.5 16.648v2.51a2 2 0 0 1-1.168 1.82l-.232.106a17 17 0 0 1-13.9.105l-.472-.207a2 2 0 0 1-1.195-1.88l.062-2.587A12 12 0 0 1 9.527 6.452l5.701-3.34c.88-.514.515-1.862-.505-1.862H9.736c-1.015 0-1.384 1.339-.512 1.859l5.42 3.231A12 12 0 0 1 20.5 16.648z"
      />
      <g strokeLinecap="round">
        <path
          strokeLinejoin="round"
          d="M14.2 11.25h-3.136a1.364 1.364 0 1 0 0 2.727h2a1.636 1.636 0 1 1 0 3.273H9.7"
        />
        <path d="M12.2 9.25v2M12.2 17.25v2" />
      </g>
    </g>
  </svg>
)
