/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMessageCircle01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgMessageCircle01Solid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M3 11.5a9.5 9.5 0 1 1 6.086 8.868l-.183-.07-.012-.004h-.004l-.158.016-5.155.533a7 7 0 0 1-.624.047c-.186.002-.52-.008-.842-.204a1.5 1.5 0 0 1-.7-1.048c-.059-.372.06-.685.133-.856.073-.172.18-.37.282-.559l1.652-3.057c.072-.133.11-.204.136-.257l.003-.008-.007-.024a11 11 0 0 0-.111-.341A9.5 9.5 0 0 1 3 11.5"
      clipRule="evenodd"
    />
  </svg>
)
