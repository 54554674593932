/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgThumbsUpSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgThumbsUpSolid"
    {...props}
  >
    <path
      fill="#000"
      d="M11.534 1c-.72 0-1.372.424-1.665 1.082L6.51 9.644a7 7 0 0 1-.16.344l-.003.006H6.34a7 7 0 0 1-.38.006h-.799c-.527 0-.981 0-1.356.03-.395.033-.789.104-1.167.297a3 3 0 0 0-1.311 1.311c-.193.378-.264.772-.296 1.167C1 13.18 1 13.635 1 14.161v4.678c0 .527 0 .982.03 1.356.033.395.104.789.297 1.167a3 3 0 0 0 1.311 1.311c.378.193.772.264 1.167.296.375.031.83.031 1.356.031A.84.84 0 0 0 6 22.161V14a1 1 0 1 1 2 0v7.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C8.76 23 9.04 23 9.6 23h6.32c.698 0 1.279 0 1.756-.036.498-.038.958-.12 1.401-.32a4 4 0 0 0 1.7-1.458c.265-.408.416-.85.529-1.337.108-.466.197-1.04.303-1.73l.535-3.481c.141-.915.257-1.665.297-2.278.043-.631.015-1.22-.2-1.786a4 4 0 0 0-1.76-2.051c-.526-.299-1.105-.416-1.735-.47C18.135 8 17.376 8 16.45 8h-.85c-.296 0-.459 0-.575-.01l-.014-.001v-.014A8 8 0 0 1 15 7.4V4.466A3.466 3.466 0 0 0 11.534 1"
    />
  </svg>
)
