/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBookEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgBookEdit"
    {...props}
  >
    <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="m10.333 20-.083-.125c-.579-.868-.868-1.302-1.25-1.617a3.3 3.3 0 0 0-1.149-.614c-.473-.144-.995-.144-2.039-.144H4.667c-.934 0-1.4 0-1.757-.182a1.67 1.67 0 0 1-.728-.728C2 16.234 2 15.767 2 14.833V7.667c0-.934 0-1.4.182-1.757.16-.314.414-.569.728-.728C3.267 5 3.733 5 4.667 5H5c1.867 0 2.8 0 3.513.363.628.32 1.137.83 1.457 1.457.363.713.363 1.646.363 3.513m0 9.667v-9.667m0 9.667.084-.125c.579-.868.868-1.302 1.25-1.617a3.3 3.3 0 0 1 1.148-.614c.474-.144.996-.144 2.04-.144H16c.933 0 1.4 0 1.757-.182.313-.16.568-.414.728-.728.182-.356.182-.157.182-1.09m-8.334-5.167c0-1.867 0-2.8.364-3.513.32-.627.83-1.137 1.456-1.457C12.866 5 13.8 5 15.667 5H16c.85 0 1.314 0 1.659.137l.016.007.04.017.14.065c.134.062.372.179.478.274M15.097 12.548c.019-.172.028-.258.054-.339a.8.8 0 0 1 .098-.202c.046-.07.107-.132.23-.254l5.503-5.503a1.179 1.179 0 0 1 1.666 1.667l-5.502 5.502c-.123.123-.184.184-.255.23a.8.8 0 0 1-.202.098c-.08.026-.167.035-.339.055l-1.41.156z" />
    </g>
  </svg>
)
