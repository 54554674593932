/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSearchUnpaidCustomer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 31 31"
    aria-label="SvgSearchUnpaidCustomer"
    {...props}
  >
    <g fill="#00A69C" filter="url(#search_unpaid_customer_svg__a)">
      <path d="M17.703 23.152q0-.338.044-.673l-.013.1a5 5 0 0 1 .315-1.2 5 5 0 0 1 .555-.983l.059-.079c.008-.01.018-.03.03-.038l-.03.037q.061-.076.124-.151a5 5 0 0 1 .66-.648l.076-.061-.037.028c.013-.015.034-.027.052-.04q.15-.112.308-.213.361-.23.755-.396l-.09.037c.407-.17.836-.287 1.274-.346l-.1.014a5.2 5.2 0 0 1 1.345 0l-.099-.014c.443.06.877.179 1.289.352l-.09-.037a5 5 0 0 1 .982.555q.037.026.072.054l.033.024c.053.04-.076-.06-.024-.018q.077.06.152.123a5 5 0 0 1 .648.66l.05.063c.018.022.012-.023-.018-.023l.03.038q.113.15.215.308.23.36.398.754l-.037-.09c.174.413.292.846.352 1.29l-.013-.1a5.2 5.2 0 0 1 0 1.345l.013-.1c-.06.441-.177.874-.35 1.287l.036-.09a5 5 0 0 1-.555.982q-.025.037-.053.072l-.025.032c-.04.054.06-.075.019-.023q-.06.076-.123.151a5 5 0 0 1-.66.648l-.064.051c-.021.018-.015.081.024-.018l-.039.03q-.15.112-.308.214a5 5 0 0 1-.664.362 5 5 0 0 1-1.289.352l.1-.013a5.2 5.2 0 0 1-1.359-.002l.1.014a5 5 0 0 1-1.263-.345l.09.037a5 5 0 0 1-1.1-.637c-.037-.027.066.052.03.025l-.022-.017-.043-.035-.107-.088a5 5 0 0 1-.369-.35 8 8 0 0 1-.186-.204q-.049-.056-.095-.113l-.04-.05h.002c.042.055.002.003-.005-.006a.4.4 0 0 0-.222-.171.38.38 0 0 0-.287.037.38.38 0 0 0-.171.222c-.022.09-.022.209.037.287a5.6 5.6 0 0 0 1.198 1.177c.438.318.93.564 1.444.735a5.44 5.44 0 0 0 3.129.08 5.42 5.42 0 0 0 3.817-3.939 5.44 5.44 0 0 0-.114-2.943 5.402 5.402 0 0 0-7.045-3.378 5.4 5.4 0 0 0-2.29 1.66 5.4 5.4 0 0 0-1.145 2.624 6 6 0 0 0-.058.77c0 .196.171.382.372.373a.38.38 0 0 0 .373-.372" />
      <path d="m19.616 21.432-.85.475-1.35.752c-.103.057.103-.057 0 0-.169-.045.17.045 0 0-.158-.283-.117-.26-.276-.544l-.75-1.35-.173-.311c-.095-.17-.343-.24-.51-.134a.38.38 0 0 0-.134.509l.474.852q.376.674.75 1.35l.173.31c.1.178.337.23.51.134l.85-.475 1.35-.752.31-.172c.17-.095.24-.343.134-.51a.376.376 0 0 0-.508-.134M21.986 20.252v2.855c0 .114.031.221.112.308.08.086.166.166.25.25l1.15 1.15c.28.281.558.565.842.842l.014.014c.137.138.39.148.526 0a.38.38 0 0 0 0-.526l-2.26-2.26.11.263v-2.8960000000000004c0-.195-.172-.382-.373-.373a.38.38 0 0 0-.371.372" />
    </g>
    <g filter="url(#search_unpaid_customer_svg__b)">
      <path
        fill="url(#search_unpaid_customer_svg__c)"
        fillRule="evenodd"
        d="M19.525 10.75c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c2.767 0 5-2.24 5-5m-4.898 12.607c0 1.21.286 2.353.795 3.364q-.446.03-.897.029c-3.494 0-6.668-1.297-9-3.41 1.445-3.868 4.932-6.59 9-6.59 1.103 0 2.163.2 3.151.57a7.5 7.5 0 0 0-1.447 1.404l-.03-.049a1.381 1.381 0 1 0-2.337 1.474l.956 1.517a7.5 7.5 0 0 0-.191 1.691"
        clipRule="evenodd"
      />
    </g>
    <g filter="url(#search_unpaid_customer_svg__d)" opacity={0.5}>
      <path
        fill="url(#search_unpaid_customer_svg__e)"
        fillRule="evenodd"
        d="M23.359 12.506c0-2.073-1.755-3.756-3.917-3.756s-3.917 1.683-3.917 3.756 1.755 3.756 3.917 3.756h.09q.64-.183 1.319-.25c1.469-.542 2.508-1.908 2.508-3.506"
        clipRule="evenodd"
      />
    </g>
    <g filter="url(#search_unpaid_customer_svg__f)">
      <path
        fill="#00A69C"
        fillRule="evenodd"
        d="M14.487 5.75c2.915 0 5.283 2.27 5.283 5.067 0 2.796-2.36 5.067-5.283 5.067-2.916 0-5.284-2.271-5.284-5.067S11.57 5.75 14.487 5.75m1.14 17.607c0 1.25.305 2.427.845 3.463q-.972.145-1.986.145c-3.48 0-6.639-1.321-8.96-3.475 1.438-3.942 4.91-6.717 8.96-6.717 1.346 0 2.628.308 3.792.862a7.5 7.5 0 0 0-1.543 1.796 1.478 1.478 0 0 0-2.044 2.013l.942 1.605a8 8 0 0 0-.006.308"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <filter
        id="search_unpaid_customer_svg__a"
        width={14.235}
        height={12.797}
        x={14.525}
        y={16.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229006" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229006" result="shape" />
      </filter>
      <filter
        id="search_unpaid_customer_svg__b"
        width={22}
        height={29}
        x={1.525}
        y={1.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={2} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229006" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229006" result="shape" />
      </filter>
      <filter
        id="search_unpaid_customer_svg__d"
        width={15.834}
        height={15.512}
        x={11.525}
        y={4.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={2} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229006" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229006" result="shape" />
      </filter>
      <filter
        id="search_unpaid_customer_svg__f"
        width={16.245}
        height={23.215}
        x={4.525}
        y={4.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229006" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229006" result="shape" />
      </filter>
      <linearGradient
        id="search_unpaid_customer_svg__c"
        x1={15.192}
        x2={7.21}
        y1={20.316}
        y2={8.088}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AF9FF" stopOpacity={0.69} />
        <stop offset={0.865} stopColor="#D4FEFD" />
      </linearGradient>
      <linearGradient
        id="search_unpaid_customer_svg__e"
        x1={22.33}
        x2={20.085}
        y1={14.535}
        y2={8.437}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#018F68" />
        <stop offset={0.865} stopColor="#02AF7F" />
      </linearGradient>
    </defs>
  </svg>
)
