/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgContentMarketing = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgContentMarketing"
    {...props}
  >
    <path d="M13.5 10h-12a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5H2v8h11V1h.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5" />
    <path
      d="M3.5 1v2M7.5 1v2M5 1.5h1M11.5 1v2M9 1.5h1M6.5 9.5v4M8.5 9.5v4M5 13.5h5"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
  </svg>
)
