/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMerchantAccount = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgMerchantAccount"
    {...props}
  >
    <path d="m4 6 2.5-2.5L4 1zM12 14V9l-2.5 2.5z" />
    <path
      d="M5.5 8.479V7.99a.49.49 0 0 0-.49-.49l-1.02.021a.49.49 0 0 0-.49.49V8.5M7 8.5H1.99a.49.49 0 0 0-.49.49v4.021c0 .27.219.49.49.49H7c.275 0 .5-.225.5-.5V9a.5.5 0 0 0-.5-.5zM1.5 11.5h6M12.5 2.479V1.99a.49.49 0 0 0-.49-.49H11c-.27 0-.5.23-.5.5v.5M14.01 2.5H8.99a.49.49 0 0 0-.49.49V7a.5.5 0 0 0 .5.5h5c.275 0 .5-.225.5-.5V2.99a.49.49 0 0 0-.49-.49zM8.5 5.5h6"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path d="M11 4h1v1.625h-1zM4 10h1v1.625H4z" />
  </svg>
)
