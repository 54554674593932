/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTag01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgTag01"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 8h.01M2 5.2v4.475c0 .489 0 .733.055.964q.075.308.24.578c.123.201.296.374.642.72l7.669 7.669c1.188 1.188 1.782 1.782 2.467 2.005a3 3 0 0 0 1.854 0c.685-.223 1.28-.817 2.467-2.005l2.212-2.212c1.188-1.188 1.782-1.782 2.005-2.467a3 3 0 0 0 0-1.854c-.223-.685-.817-1.28-2.005-2.467l-7.669-7.669c-.346-.346-.519-.519-.72-.642a2 2 0 0 0-.578-.24C10.409 2 10.164 2 9.675 2H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 3.52 2 4.08 2 5.2M8.5 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"
    />
  </svg>
)
