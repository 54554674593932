/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAward = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 13 16"
    aria-label="SvgAward"
    {...props}
  >
    <path
      fill="#32497F"
      fillRule="evenodd"
      d="m4.925 1.949 1.117-.807a.81.81 0 0 1 .918 0l1.117.807.101.073.124.017 1.37.187.012.001c.328.037.58.29.617.617l.002.017.003.017.25 1.371.02.111.066.092.808 1.12c.178.249.2.643-.01.988l-.798 1.107-.073.101-.017.124-.187 1.37-.002.013a.694.694 0 0 1-.615.616l-.016.001-.016.003-1.432.25-.114.02-.093.067-1.12.808c-.248.177-.639.2-.983-.006l-1.038-.794-.105-.08-.131-.018-1.371-.187-.014-.002a.694.694 0 0 1-.615-.615l-.002-.016-.003-.016-.249-1.433-.02-.114-.067-.094-.808-1.118c-.177-.248-.2-.64.006-.983l.794-1.038.08-.105.018-.131.184-1.348a.98.98 0 0 1 .719-.72L4.7 2.04l.123-.017zM6.5 0c-.37 0-.73.113-1.033.323L5.461.33l-1.02.736-1.247.17-.02.003-.021.004a1.98 1.98 0 0 0-1.5 1.5l-.005.021-.003.021-.17 1.24-.728.953-.014.019-.013.02c-.406.648-.426 1.493.017 2.113l.002.002.742 1.027.227 1.305a1.7 1.7 0 0 0 .84 1.27l-1.226 1.912c-.415.649.1 1.478.853 1.423l1.136-.084.452.895c.335.662 1.288.69 1.645.023l1.345-2.511 1.423 2.563c.366.66 1.317.62 1.643-.046l.504-1.028 1.07.066c.723.044 1.228-.73.87-1.374l-1.277-2.3c.205-.252.334-.557.37-.882l.17-1.24.736-1.02.01-.013.008-.014c.406-.648.427-1.493-.017-2.113l-.001-.002-.743-1.03-.226-1.243A1.694 1.694 0 0 0 9.8 1.234l-1.24-.17L7.54.33 7.533.323A1.8 1.8 0 0 0 6.501 0m3.65 10.826q-.137.04-.278.057l-1.304.227-.987.712 1.4 2.523.463-.947a.93.93 0 0 1 .892-.519l.986.06zm-4.352 1.228a2 2 0 0 1-.373-.184l-.02-.012-.019-.014-.953-.73-.915-.124.036.023-1.31 2.047 1.04-.077a.93.93 0 0 1 .898.509l.41.813z"
      clipRule="evenodd"
    />
  </svg>
)
