/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDownloadCloud01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgDownloadCloud01Solid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M24 14a6 6 0 0 0-4.509-5.813A8.003 8.003 0 0 0 4.968 7.18 6.5 6.5 0 0 0 6.5 20H18a6 6 0 0 0 6-6M12 7a1 1 0 0 1 1 1v5.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L11 13.586V8a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
  </svg>
)
