/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgUtilities = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgUtilities"
    {...props}
  >
    <path d="M9.5 1a.5.5 0 0 0-.5.5V3H4.5a.5.5 0 0 0-.5.5V7H2.5a.5.5 0 0 0-.5.5V14h1V8h10v6h1V7.5a.5.5 0 0 0-.5-.5H12V1.5a.5.5 0 0 0-.5-.5zm.5 1h1v5H5V4h5zM6 5v1h1V5zm2 0v1h1V5zM5.5 9l-2 2H5c0 .809.393 1.286.637 1.545.243.258.363.316.363.955v.5h1v-.5c0-.861-.38-1.366-.637-1.639C6.107 11.59 6 11.566 6 11h1.5zm2 2H9c0 .809.393 1.286.637 1.545.243.258.363.316.363.955v.5h1v-.5c0-.861-.38-1.366-.637-1.639C10.107 11.59 10 11.566 10 11h1.5l-2-2z" />
  </svg>
)
