/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSortZToA = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 50 38"
    aria-label="SvgSortZToA"
    {...props}
  >
    <g fill="#00a69c">
      <path d="m8 23.152 6.462-7.668H8.234V13h9.63v2.484l-6.336 7.668h6.552v2.592H8zM34.85 13h2.322l5.544 12.744h-3.168l-1.098-2.7H33.5l-1.062 2.7h-3.096zm1.08 3.708-1.548 3.96h3.114zM29.39 20.096a.5.5 0 0 0 0-.707l-3.182-3.182a.5.5 0 1 0-.707.707l2.828 2.828-2.828 2.829a.5.5 0 1 0 .707.707zm-8.354.146h8v-1h-8z" />
    </g>
  </svg>
)
