/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMarkerPin02 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgMarkerPin02"
    {...props}
  >
    <g stroke="#000" strokeLinecap="round" strokeLinejoin="round">
      <path d="M12 12.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
      <path d="M12 22c2-4 8-6.582 8-12a8 8 0 1 0-16 0c0 5.418 6 8 8 12" />
    </g>
  </svg>
)
