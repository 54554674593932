/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMarketing = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgMarketing"
    {...props}
  >
    <path d="m11.418 1.95-.855.51c.273.454.437.978.437 1.54h1c0-.754-.223-1.45-.582-2.05M9.707 2.976l-.863.515c.09.153.156.32.156.508h1c0-.375-.113-.723-.293-1.023M4 4C1.797 4 0 5.797 0 8s1.797 4 4 4h4V9H4c-.559 0-1-.441-1-1s.441-1 1-1h4V4Zm0 1h2v1H4c-1.098 0-2 .902-2 2s.902 2 2 2h2v1H4a2.99 2.99 0 0 1-3-3c0-1.664 1.336-3 3-3m9 0c-1.086 0-2 .863-2 1.938 0 .558.246 1.054.633 1.406C10.668 8.848 10 9.844 10 11h1c0-1.11.89-2 2-2s2 .89 2 2h1c0-1.156-.668-2.152-1.633-2.656.387-.352.633-.848.633-1.406C15 5.863 14.086 5 13 5m0 1c.57 0 1 .426 1 .938 0 .515-.43.945-1 .945s-1-.43-1-.945c0-.512.43-.938 1-.938m-4 6a.97.97 0 0 1-.156.504l.867.52c.18-.301.289-.649.289-1.024Zm2 0c0 .563-.164 1.086-.437 1.535l.855.512c.36-.598.582-1.293.582-2.047Z" />
  </svg>
)
