/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCreditCardEditSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgCreditCardEditSolid"
    {...props}
  >
    <g fill="#000">
      <path
        fillRule="evenodd"
        d="M19.293 13.293a2.414 2.414 0 0 1 3.414 3.414l-4.157 4.158-.027.026c-.099.1-.224.225-.372.328q-.194.135-.414.222c-.168.066-.341.1-.48.127l-.036.008-2.025.405a1 1 0 0 1-1.177-1.177l.405-2.025.008-.037c.027-.138.061-.311.127-.48q.087-.218.222-.413c.103-.148.229-.273.328-.372l.026-.027z"
        clipRule="evenodd"
      />
      <path d="M18.839 4H5.16c-.527 0-.981 0-1.356.03-.395.033-.789.104-1.167.297a3 3 0 0 0-1.311 1.311c-.193.378-.264.772-.296 1.167C1 7.18 1 7.635 1 8.161c0 .464.375.839.839.839H22.16A.84.84 0 0 0 23 8.161c0-.527 0-.981-.03-1.356-.033-.395-.104-.789-.297-1.167a3 3 0 0 0-1.311-1.311c-.378-.193-.772-.264-1.167-.296C19.821 4 19.365 4 18.839 4M1.546 11.109C1.76 11 2.04 11 2.6 11h14.993c.365 0 .544.62.286.879l-4.158 4.157-.019.02a5 5 0 0 0-.562.649q-.27.388-.443.828c-.138.352-.206.71-.229.827l-.005.027-.194.97c-.046.229-.069.343-.128.43a.5.5 0 0 1-.21.171c-.096.042-.213.042-.446.042H5.16c-.527 0-.982 0-1.356-.03-.395-.033-.789-.104-1.167-.297a3 3 0 0 1-1.311-1.311c-.193-.378-.264-.772-.296-1.167C1 16.82 1 16.365 1 15.839V12.6c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437" />
    </g>
  </svg>
)
