/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMap01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgMap01Solid"
    {...props}
  >
    <g fill="#000">
      <path d="M8 2.798c0-.452 0-.679-.095-.81a.5.5 0 0 0-.351-.204c-.161-.017-.358.095-.75.32l-5.3 3.028A1 1 0 0 0 1 6v16a1 1 0 0 0 1.496.868l4.698-2.684c.293-.168.44-.252.547-.37a1 1 0 0 0 .21-.36c.049-.152.049-.321.049-.66zM10 18.795c0 .338 0 .507.05.658a1 1 0 0 0 .209.361c.107.118.254.202.547.37l2.997 1.712c.393.225.59.337.75.32a.5.5 0 0 0 .352-.204c.095-.131.095-.358.095-.81V5.205c0-.338 0-.507-.05-.658a1 1 0 0 0-.209-.361c-.107-.118-.254-.202-.547-.37l-2.997-1.712c-.393-.225-.59-.337-.75-.32a.5.5 0 0 0-.352.204c-.095.131-.095.358-.095.81zM23 2a1 1 0 0 0-1.555-.832l-3.732 2.489c-.261.173-.391.26-.485.375a1 1 0 0 0-.185.344C17 4.518 17 4.675 17 4.988V21.04c0 .486 0 .729.101.863a.5.5 0 0 0 .37.197c.167.01.369-.125.773-.394l4.31-2.874A1 1 0 0 0 23 18z" />
    </g>
  </svg>
)
