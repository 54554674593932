/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMessageQuestionSquareSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgMessageQuestionSquareSolid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M16.241 2H7.76c-.805 0-1.47 0-2.01.044-.563.046-1.08.145-1.565.392a4 4 0 0 0-1.748 1.748c-.247.485-.346 1.002-.392 1.564C2 6.29 2 6.954 2 7.758v6.38c0 .795 0 1.386.136 1.897a4 4 0 0 0 2.829 2.829c.307.082.643.115 1.035.128v1.379c0 .235 0 .475.018.672.017.187.062.542.31.852a1.5 1.5 0 0 0 1.174.565c.397 0 .702-.187.86-.291.164-.109.351-.259.535-.406l2.413-1.93c.519-.415.673-.532.832-.614q.243-.123.508-.178c.176-.036.37-.041 1.034-.041h2.557c.805 0 1.47 0 2.01-.044.563-.046 1.08-.145 1.565-.392a4 4 0 0 0 1.748-1.748c.247-.485.346-1.002.392-1.564.044-.541.044-1.206.044-2.01V7.758c0-.805 0-1.47-.044-2.01-.046-.563-.145-1.08-.392-1.565a4 4 0 0 0-1.748-1.748c-.485-.247-1.002-.346-1.564-.392C17.71 2 17.046 2 16.242 2M11.49 7.672a1.248 1.248 0 0 1 1.88 1.078v.002c0 .218-.177.52-.678.854a4.3 4.3 0 0 1-.88.443l-.009.003h.002a1 1 0 0 0-.632 1.264c.173.519.75.804 1.267.632q.068-.023.135-.049.116-.043.305-.126c.245-.109.58-.275.921-.503.622-.415 1.57-1.238 1.57-2.518A3.248 3.248 0 0 0 9.057 7.67a1 1 0 0 0 1.887.664c.097-.278.29-.513.545-.662M12.15 13a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2z"
      clipRule="evenodd"
    />
  </svg>
)
