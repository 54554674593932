/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgTwitter"
    {...props}
  >
    <path d="M15 3.297c-.523.226-1.05.394-1.633.45a3 3 0 0 0 1.281-1.52 5.5 5.5 0 0 1-1.804.675C12.258 2.34 11.5 2 10.684 2c-1.575 0-2.86 1.242-2.86 2.766 0 .222 0 .449.059.62-3.008 0-5.07-1.695-6.469-3.386-.293.395-.41.902-.41 1.41 0 .957.527 1.805 1.285 2.313-.469-.055-.934-.168-1.285-.336v.054A2.76 2.76 0 0 0 3.28 8.148c-.234.055-.469.114-.761.114-.172 0-.348 0-.524-.055.352 1.129 1.98 2.426 3.262 2.426C4.266 11.363 3.344 12 1.5 12H1c1.281.79 2.8 1 4.375 1 5.309 0 8.168-4.23 8.168-7.898v-.336c.582-.399 1.05-.903 1.457-1.47" />
  </svg>
)
