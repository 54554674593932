/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCreditCardAngleGradient = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 26 24"
    aria-label="SvgCreditCardAngleGradient"
    {...props}
  >
    <path
      stroke="url(#credit-card-angle-gradient_svg__a)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m20.66 5.268-17.32 10m9.794-1.036-4.33 2.5M2.44 13.709l3.8 6.582c.56.97.84 1.455 1.243 1.716a2 2 0 0 0 1.194.32c.48-.025.964-.305 1.934-.865l11.778-6.8c.97-.56 1.455-.84 1.717-1.243a2 2 0 0 0 .32-1.194c-.026-.48-.306-.964-.866-1.934l-3.8-6.582c-.56-.97-.84-1.455-1.242-1.717a2 2 0 0 0-1.194-.32c-.48.026-.965.306-1.935.866l-11.778 6.8c-.97.56-1.455.84-1.716 1.243a2 2 0 0 0-.32 1.194c.025.479.305.964.865 1.934"
    />
    <defs>
      <linearGradient
        id="credit-card-angle-gradient_svg__a"
        x1={8.811}
        x2={49.225}
        y1={65.891}
        y2={62.71}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#399CD4" />
        <stop offset={1} stopColor="#5E5BE9" />
      </linearGradient>
    </defs>
  </svg>
)
