/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCloudRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgCloudRefresh"
    {...props}
  >
    <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M6.5 19a4.5 4.5 0 0 1-.42-8.98 6.002 6.002 0 0 1 11.84 0A4.5 4.5 0 0 1 17.5 19z" />
      <path d="M8.822 11.45s.042-.297 1.272-1.527a3.15 3.15 0 0 1 5.3 1.527m-6.572 0v-2.1m0 2.1h2.1m4.4 2.45s-.043.297-1.273 1.527a3.15 3.15 0 0 1-5.3-1.527m6.572 0V16m0-2.1h-2.1" />
    </g>
  </svg>
)
