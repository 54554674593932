/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCheckVerified01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgCheckVerified01Solid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M15.134 2.154a4.83 4.83 0 0 0-6.268 0 2.83 2.83 0 0 1-1.612.668 4.83 4.83 0 0 0-4.432 4.432 2.83 2.83 0 0 1-.668 1.612 4.83 4.83 0 0 0 0 6.268c.387.454.62 1.018.668 1.612a4.83 4.83 0 0 0 4.432 4.432 2.83 2.83 0 0 1 1.612.668 4.83 4.83 0 0 0 6.268 0 2.83 2.83 0 0 1 1.612-.668 4.83 4.83 0 0 0 4.432-4.432 2.83 2.83 0 0 1 .668-1.612 4.83 4.83 0 0 0 0-6.268 2.83 2.83 0 0 1-.668-1.612 4.83 4.83 0 0 0-4.432-4.432 2.83 2.83 0 0 1-1.612-.668m1.073 8.053a1 1 0 0 0-1.414-1.414L11 12.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0z"
      clipRule="evenodd"
    />
  </svg>
)
