/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgGitPullRequestClosed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgGitPullRequestClosed"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18 15a3 3 0 1 0 0 6 3 3 0 0 0 0-6m0 0v-5M6 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6m0 0v12M20 4l-4 4m0-4 4 4"
    />
  </svg>
)
