/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSoftwareWebServices = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="-0.5 0 16 16"
    aria-label="SvgSoftwareWebServices"
    {...props}
  >
    <path d="M1.5 2C.676 2 0 2.676 0 3.5v7c0 .824.676 1.5 1.5 1.5h5.492v.512c.004.246.004.304.004.488H4v1h7v-1H7.996c0-.184 0-.246-.004-.492V12H13.5c.824 0 1.5-.676 1.5-1.5v-7c0-.824-.676-1.5-1.5-1.5Zm0 1h12c.281 0 .5.219.5.5v7c0 .281-.219.5-.5.5h-12a.494.494 0 0 1-.5-.5v-7c0-.281.219-.5.5-.5" />
  </svg>
)
