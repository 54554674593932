/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgLayersThree01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgLayersThree01Solid"
    {...props}
  >
    <g fill="#000" fillRule="evenodd" clipRule="evenodd">
      <path d="M1.106 11.553a1 1 0 0 1 1.341-.447L12 15.882l9.553-4.776a1 1 0 1 1 .894 1.788l-9.642 4.821-.026.013c-.093.048-.286.147-.502.187a1.5 1.5 0 0 1-.554 0c-.216-.04-.41-.139-.502-.186l-.026-.013-9.642-4.822a1 1 0 0 1-.447-1.341" />
      <path d="M1.106 16.553a1 1 0 0 1 1.341-.447L12 20.882l9.553-4.776a1 1 0 1 1 .894 1.788l-9.642 4.822-.026.013c-.093.047-.286.146-.502.186a1.5 1.5 0 0 1-.554 0c-.216-.04-.41-.139-.502-.186l-.026-.013-9.642-4.822a1 1 0 0 1-.447-1.341M11.723 1.085a1.5 1.5 0 0 1 .554 0 1.9 1.9 0 0 1 .528.2l9.642 4.82a1 1 0 0 1 0 1.79l-9.642 4.82-.026.013c-.093.048-.286.147-.502.187a1.5 1.5 0 0 1-.554 0c-.216-.04-.41-.139-.502-.187l-.026-.013-9.642-4.82a1 1 0 0 1 0-1.79l9.642-4.82.026-.014c.093-.047.286-.146.502-.186" />
    </g>
  </svg>
)
