/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCreditCardMinusSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgCreditCardMinusSolid"
    {...props}
  >
    <g fill="#000">
      <path
        fillRule="evenodd"
        d="M15 18a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1"
        clipRule="evenodd"
      />
      <path d="M18.839 4H5.16c-.527 0-.981 0-1.356.03-.395.033-.789.104-1.167.297a3 3 0 0 0-1.311 1.311c-.193.378-.264.772-.296 1.167C1 7.18 1 7.635 1 8.161c0 .464.375.839.839.839H22.16A.84.84 0 0 0 23 8.161c0-.527 0-.981-.03-1.356-.033-.395-.104-.789-.297-1.167a3 3 0 0 0-1.311-1.311c-.378-.193-.772-.264-1.167-.296C19.821 4 19.365 4 18.839 4M1.546 11.109C1.76 11 2.04 11 2.6 11h18.8c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C23 11.76 23 12.04 23 12.6v1.551c0 .291 0 .437-.03.519-.062.171-.129.238-.3.3-.082.03-.278.03-.67.03h-6a3 3 0 0 0-2.974 3.399c.103.776.155 1.165.107 1.286-.058.145-.084.175-.22.251-.114.064-.39.064-.939.064H5.161c-.527 0-.982 0-1.356-.03-.395-.033-.789-.104-1.167-.297a3 3 0 0 1-1.311-1.311c-.193-.378-.264-.772-.296-1.167C1 16.821 1 16.365 1 15.839V12.6c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437" />
    </g>
  </svg>
)
