/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMarkerPin02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgMarkerPin02Solid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M3 10a9 9 0 0 1 18 0c0 3.12-1.744 5.366-3.529 7.224-.43.449-.875.886-1.304 1.308l-.056.055c-.45.443-.884.87-1.292 1.3-.82.866-1.49 1.692-1.925 2.56a1 1 0 0 1-1.788 0c-.434-.868-1.105-1.694-1.925-2.56-.408-.43-.842-.857-1.292-1.3l-.056-.055c-.43-.422-.874-.859-1.304-1.308C4.744 15.365 3 13.12 3 10m12-.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0"
      clipRule="evenodd"
    />
  </svg>
)
