/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDetectedBankAccount = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 32 32"
    aria-label="SvgDetectedBankAccount"
    {...props}
  >
    <path
      fill="url(#detected-bank-account_svg__a)"
      d="m3.25 6.5.392.784c.133.266.2.399.288.514a1.5 1.5 0 0 0 .272.272c.115.089.248.155.514.288l.784.392-.784.392c-.266.133-.399.2-.514.288a1.5 1.5 0 0 0-.272.272c-.089.115-.155.248-.288.514L3.25 11l-.392-.784c-.133-.266-.2-.399-.288-.514a1.5 1.5 0 0 0-.272-.272c-.115-.089-.248-.155-.514-.288L1 8.75l.784-.392c.266-.133.399-.2.514-.288a1.5 1.5 0 0 0 .272-.272c.089-.115.155-.248.288-.514z"
    />
    <path
      fill="url(#detected-bank-account_svg__b)"
      d="m7.5 1 .59 1.532c.14.367.21.55.32.704a1.5 1.5 0 0 0 .354.354c.154.11.337.18.704.32L11 4.5l-1.532.59c-.367.14-.55.21-.704.32a1.5 1.5 0 0 0-.354.354c-.11.154-.18.337-.32.704L7.5 8l-.59-1.532c-.14-.367-.21-.55-.32-.704a1.5 1.5 0 0 0-.354-.354c-.154-.11-.337-.18-.704-.32L4 4.5l1.532-.59c.367-.14.55-.21.704-.32a1.5 1.5 0 0 0 .354-.354c.11-.154.18-.337.32-.704z"
    />
    <path
      fill="url(#detected-bank-account_svg__c)"
      fillRule="evenodd"
      d="M15.854 7.693q.146-.012.29 0c.112.01.219.034.303.053l6.211 1.38c.167.037.333.074.474.116.156.047.335.116.508.24.245.178.438.418.557.696.085.196.113.385.125.548.01.146.01.316.01.487v.98c0 .21 0 .415-.013.589-.016.19-.053.415-.168.641-.16.314-.415.569-.728.729a1.7 1.7 0 0 1-.642.167l-.115.007v5.014q.06.003.115.008c.19.015.416.052.642.167.313.16.568.415.728.728.115.227.152.451.168.642.014.173.014.378.014.588v.72c0 .21 0 .415-.014.589-.016.19-.053.415-.168.641-.16.314-.415.569-.728.729a1.7 1.7 0 0 1-.642.167 8 8 0 0 1-.588.014H9.806c-.21 0-.415 0-.589-.014a1.7 1.7 0 0 1-.641-.167 1.67 1.67 0 0 1-.728-.729 1.7 1.7 0 0 1-.168-.641 8 8 0 0 1-.014-.589v-.72c0-.21 0-.415.014-.588.016-.19.052-.416.168-.642.16-.313.414-.568.728-.728.226-.115.451-.152.641-.168l.116-.007v-5.014l-.116-.007a1.7 1.7 0 0 1-.641-.167 1.67 1.67 0 0 1-.728-.729 1.7 1.7 0 0 1-.168-.641 8 8 0 0 1-.014-.589v-.98c0-.17 0-.34.01-.487.013-.163.04-.352.125-.549.12-.277.312-.517.557-.694.174-.125.352-.194.51-.24.14-.043.305-.08.472-.117l6.212-1.38c.084-.02.19-.043.302-.053m-4.855 6.64v5h2.084v-5zm3.75 0v5h2.5v-5zm4.167 0v5h2.083v-5z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="detected-bank-account_svg__a"
        x1={0.86}
        x2={11.648}
        y1={14.12}
        y2={13.059}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C92BE" stopOpacity={0.8} />
        <stop offset={1} stopColor="#3D3AC2" stopOpacity={0.54} />
      </linearGradient>
      <linearGradient
        id="detected-bank-account_svg__b"
        x1={0.86}
        x2={11.648}
        y1={14.12}
        y2={13.059}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C92BE" stopOpacity={0.8} />
        <stop offset={1} stopColor="#3D3AC2" stopOpacity={0.54} />
      </linearGradient>
      <linearGradient
        id="detected-bank-account_svg__c"
        x1={7.432}
        x2={25.412}
        y1={29.527}
        y2={27.756}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C92BE" stopOpacity={0.8} />
        <stop offset={1} stopColor="#3D3AC2" stopOpacity={0.54} />
      </linearGradient>
    </defs>
  </svg>
)
