/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPayrollFee = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgPayrollFee"
    {...props}
  >
    <circle cx={6.5} cy={7.5} r={0.5} />
    <circle cx={9.5} cy={7.5} r={0.5} />
    <path d="M10.5 2.75S10.5 5.5 5 5.5" fill="none" stroke="#000" strokeMiterlimit="10" />
    <path
      d="M11.721 10q.086-.245.154-.5c.008-.03.029-.093.029-.093s.447.093.596.093c.552 0 1-.948 1-1.5a.993.993 0 0 0-.941-.988C12.8 4.899 12.29 3.343 10.5 2.75c0-.69-1-1.25-2.5-1.25-3 0-4.229 1.264-4.548 3.075-.13.738-.113 1.566-.014 2.437A.994.994 0 0 0 2.5 8c0 .552.448 1.5 1 1.5.148 0 .595-.093.595-.093s.022.063.03.093c.616 2.334 2.405 4 3.875 4q0 0 0 0"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
    <path d="M15 11v4H9v-4zm1-1H8v6h8z" />
    <path d="M12 12a1 1 0 1 0 0 2 1 1 0 0 0 0-2M15 10c-.552 0-1 .459-1 1.026s.448 1.026 1 1.026 1-.459 1-1.026S15.552 10 15 10M9 10a1 1 0 1 0 0 2 1 1 0 0 0 0-2M9 14a1 1 0 1 0 0 2 1 1 0 0 0 0-2M15 14a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
  </svg>
)
