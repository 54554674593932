/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFinanceAccounting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgFinanceAccounting"
    {...props}
  >
    <path
      d="M4.5 13.5h8a1 1 0 0 0 1-1v-10a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1zM3.5 4.5h10M3.5 7.5h10M3.5 10.5h10"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <ellipse cx={6} cy={4.5} rx={1} ry={1.5} />
    <ellipse cx={9} cy={4.5} rx={1} ry={1.5} />
    <ellipse cx={11} cy={7.5} rx={1} ry={1.5} />
    <ellipse cx={9.125} cy={10.5} rx={1} ry={1.5} />
    <ellipse cx={6} cy={10.5} rx={1} ry={1.5} />
  </svg>
)
