/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgPr"
    {...props}
  >
    <path d="M15 5.5H1" fill="none" stroke="#000" strokeMiterlimit="10" />
    <circle cx={12} cy={2} r={1} />
    <path
      d="m2.375 5.5 1 2h9.25l1-2M12 2l1.5 1.25V4M5.5 14V7.5M10.5 7.5V14"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
