/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSearchTransaction = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="-2 -2 31 31"
    aria-label="SvgSearchTransaction"
    {...props}
  >
    <rect
      width={18.385}
      height={14.299}
      x={6.81}
      y={8.172}
      fill="#00A69C"
      fillOpacity={0.3}
      rx={4}
    />
    <g filter="url(#search_transaction_svg__a)">
      <rect width={18.385} height={14.299} x={5.388} y={6.91} fill="#00A69C" rx={3} />
    </g>
    <path fill="#83E2E3" d="M5.388 9.977h18.385v3.064H5.388z" />
    <rect width={8.171} height={2.043} x={7.431} y={16.102} fill="#83E2E3" rx={1.021} />
    <rect width={2.043} height={2.043} x={18.666} y={16.102} fill="#83E2E3" rx={1.021} />
    <defs>
      <filter
        id="search_transaction_svg__a"
        width={20.385}
        height={16.301}
        x={4.388}
        y={5.91}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229011" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229011" result="shape" />
      </filter>
    </defs>
  </svg>
)
