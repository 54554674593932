/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBankAccounts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgBankAccounts"
    {...props}
  >
    <path
      d="M1 13.5h6.5M13.5 6.5h-12v-.67c0-.2.102-.381.26-.46L7.5 2.5l5.74 2.87c.158.079.26.26.26.46zM10.5 6v3.5M12.5 6v3.5M4.5 6v7M2.5 6v7"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle cx={7.5} cy={4.5} r={0.5} />
    <path
      d="M8.5 15.5h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path d="M9 13h1v1H9zM11 13h1v1h-1zM13 13h1v1h-1zM12 11h2v1h-2z" />
  </svg>
)
