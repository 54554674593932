/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMastercardCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 70 48"
    aria-label="SvgMastercardCard"
    {...props}
  >
    <rect width={70} height={48} fill="#fff" rx={6} />
    <path
      fill="#ED0006"
      fillRule="evenodd"
      d="M35.5 34.314a13.5 13.5 0 0 1-8.852 3.298C19.11 37.612 13 31.43 13 23.806S19.11 10 26.648 10c3.378 0 6.469 1.242 8.852 3.298A13.5 13.5 0 0 1 44.353 10C51.89 10 58 16.181 58 23.806s-6.11 13.806-13.647 13.806c-3.379 0-6.47-1.242-8.853-3.298"
      clipRule="evenodd"
    />
    <path
      fill="#F9A000"
      fillRule="evenodd"
      d="M35.5 34.314a13.84 13.84 0 0 0 4.795-10.508A13.84 13.84 0 0 0 35.5 13.298 13.5 13.5 0 0 1 44.353 10C51.89 10 58 16.181 58 23.806s-6.11 13.806-13.647 13.806c-3.379 0-6.47-1.242-8.853-3.298"
      clipRule="evenodd"
    />
    <path
      fill="#FF5E00"
      fillRule="evenodd"
      d="M35.5 13.298a13.84 13.84 0 0 1 4.795 10.508A13.84 13.84 0 0 1 35.5 34.314a13.84 13.84 0 0 1-4.795-10.508c0-4.208 1.86-7.976 4.795-10.508"
      clipRule="evenodd"
    />
  </svg>
)
