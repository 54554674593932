/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgHome05Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgHome05Solid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M11.477 1.336a2 2 0 0 1 1.046 0c.397.108.732.37.999.58 2.303 1.809 4.62 3.599 6.934 5.394.377.292.709.55.956.884.217.294.379.624.477.976.112.4.112.82.111 1.297-.003 2.457 0 4.914 0 7.372 0 .527 0 .982-.03 1.356-.033.395-.104.789-.297 1.167a3 3 0 0 1-1.311 1.311c-.378.193-.772.264-1.167.296-.802.066-1.615.03-2.418.031-.12 0-.262 0-.387-.01a1.5 1.5 0 0 1-.571-.153 1.5 1.5 0 0 1-.656-.656 1.5 1.5 0 0 1-.153-.571 5 5 0 0 1-.01-.387l.008-5.596c0-.56.001-.841-.108-1.056a1 1 0 0 0-.437-.437c-.214-.11-.494-.11-1.055-.11h-2.8c-.56 0-.84 0-1.053.11a1 1 0 0 0-.437.436c-.109.213-.11.493-.11 1.052L9 20.223c0 .12 0 .261-.01.387a1.5 1.5 0 0 1-.154.571 1.5 1.5 0 0 1-.655.656 1.5 1.5 0 0 1-.571.153c-.126.01-.268.01-.387.01-.804 0-1.617.035-2.418-.03-.395-.033-.789-.104-1.167-.297a3 3 0 0 1-1.311-1.311c-.193-.378-.264-.772-.297-1.167-.03-.374-.03-.83-.03-1.356q.002-3.687 0-7.372c0-.477-.001-.897.11-1.297a3 3 0 0 1 .478-.976c.247-.334.579-.592.956-.884 2.313-1.795 4.631-3.585 6.934-5.394.267-.21.602-.472 1-.58"
      clipRule="evenodd"
    />
  </svg>
)
