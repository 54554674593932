/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgConvertibleNotes = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgConvertibleNotes"
    {...props}
  >
    <path d="M4.5 11V2.5h8v10c0 .603-.401 1-1 1" fill="none" stroke="#000" strokeMiterlimit="10" />
    <path
      d="M2.5 12.5v-1h8v.875c0 .603.401 1.125 1 1.125h-8c-.599 0-1-.397-1-1z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
