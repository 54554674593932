/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgShipping = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgShipping"
    {...props}
  >
    <path
      d="M9.5 11.5v-8a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h1M11.548 11.5H4.5"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M9 5.5h3.937a1 1 0 0 1 .832.445l1.561 2.303c.11.164.168.357.168.555V10.5a1 1 0 0 1-1 1h-.949"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle cx={3.5} cy={11.5} r={1} fill="none" stroke="#000" strokeMiterlimit="10" />
    <circle cx={12.472} cy={11.5} r={1} fill="none" stroke="#000" strokeMiterlimit="10" />
  </svg>
)
