/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgEducation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgEducation"
    {...props}
  >
    <path
      d="m8.334 7.605.501.362.613.083c.25.028.445.223.473.473l.111.613.362.501a.63.63 0 0 1 0 .696l-.362.501-.082.614a.534.534 0 0 1-.473.473l-.64.111-.501.362a.63.63 0 0 1-.696 0l-.473-.362-.615-.082a.534.534 0 0 1-.473-.473l-.111-.641-.362-.501a.63.63 0 0 1 0-.696l.362-.473.082-.614a.66.66 0 0 1 .501-.501l.613-.083.501-.362a.58.58 0 0 1 .669-.001z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M6.078 11.5H2.5a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H9.95M4 5.5h8"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path d="m7.559 12.335-.394-.301-.613-.084a.53.53 0 0 1-.332-.17L4 14h1v1zM9.78 11.78a.54.54 0 0 1-.304.142l-.641.112-.425.307L11 15v-1h1z" />
  </svg>
)
