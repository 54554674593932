/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBranding = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgBranding"
    {...props}
  >
    <path
      d="M13.5 11.5h-12a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1zM7.5 11.5c-.014.025 0 2 0 2M4 13.5h7"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path d="M9.847 6.047c-.027.017-.662.369-.662 1.151 0 .811.697 1.152.815 1.205C9.978 8.497 9.378 10 8.655 10c-.389 0-.529-.246-.978-.246-.483 0-.619.246-.988.246-.604 0-1.581-1.294-1.581-2.712 0-1.085.65-1.837 1.498-1.837.393 0 .742.27.982.27.23 0 .659-.27 1.145-.27.208 0 .767.063 1.114.596M7.79 5.375c-.07-.349.123-.699.303-.922.23-.27.592-.453.905-.453.02.349-.106.692-.336.942-.2.27-.55.473-.872.433" />
  </svg>
)
