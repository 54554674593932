/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgWallet05 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgWallet05"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19 10H5m14 0 1.864 3.107c.418.696.627 1.044.735 1.42a3 3 0 0 1 .113 1.023c-.025.389-.153.774-.41 1.544l-.208.624c-.393 1.18-.59 1.77-.955 2.207a3 3 0 0 1-1.203.867c-.53.208-1.151.208-2.396.208H7.46c-1.244 0-1.866 0-2.396-.208a3 3 0 0 1-1.203-.867c-.365-.437-.562-1.027-.955-2.207l-.208-.624c-.257-.77-.385-1.155-.41-1.545a3 3 0 0 1 .113-1.023c.108-.375.317-.723.735-1.42L5 10m14 0 .417-1.46c.198-.693.297-1.039.22-1.313a1 1 0 0 0-.436-.577c-.242-.15-.602-.15-1.322-.15H6.12c-.72 0-1.08 0-1.322.15a1 1 0 0 0-.435.577c-.078.274.021.62.219 1.313L5 10m7-3.5H8.464c-.52 0-1.02-.21-1.389-.586C6.707 5.54 6.5 5.03 6.5 4.5s.207-1.04.575-1.414a1.95 1.95 0 0 1 1.39-.586c2.75 0 3.535 4 3.535 4m0 0h3.536c.52 0 1.02-.21 1.389-.586.368-.375.575-.884.575-1.414s-.207-1.04-.575-1.414a1.95 1.95 0 0 0-1.39-.586c-2.75 0-3.535 4-3.535 4"
    />
  </svg>
)
