/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCheckVerified02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgCheckVerified02Solid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M2.031 12q0-.203-.078-.393L1.23 9.86a3.04 3.04 0 0 1 .658-3.306c.281-.282.616-.505.984-.658l1.744-.722c.252-.105.454-.305.559-.557l.722-1.745a3.033 3.033 0 0 1 3.963-1.641l1.743.722c.253.104.537.104.79 0l.002-.002 1.744-.72a3.034 3.034 0 0 1 3.962 1.642l.705 1.703.018.04c.105.253.305.454.558.56l1.745.722a3.033 3.033 0 0 1 1.641 3.962l-.722 1.744a1.03 1.03 0 0 0 0 .791l.722 1.744a3.034 3.034 0 0 1-1.641 3.963l-1.745.723a1.03 1.03 0 0 0-.576.6l-.705 1.702a3.03 3.03 0 0 1-3.962 1.641l-1.744-.72h-.002a1.03 1.03 0 0 0-.79 0l-1.743.721a3.03 3.03 0 0 1-3.963-1.641l-.722-1.745a1.03 1.03 0 0 0-.56-.557l-1.743-.722A3.033 3.033 0 0 1 1.23 14.14l.722-1.747c.052-.126.078-.26.078-.393m14.176-1.793a1 1 0 0 0-1.414-1.414L11 12.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0z"
      clipRule="evenodd"
    />
  </svg>
)
