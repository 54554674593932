/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCloudBlank01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgCloudBlank01Solid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M5.25 9.143a7.002 7.002 0 0 1 13.5 0A5.502 5.502 0 0 1 17.5 20h-11A5.5 5.5 0 0 1 5.25 9.143"
      clipRule="evenodd"
    />
  </svg>
)
