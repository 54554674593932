/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgStar05Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgStar05Solid"
    {...props}
  >
    <path
      fill="#000"
      d="M12.97 1.757a1 1 0 0 0-1.94 0L9.728 6.963c-.27 1.084-.362 1.398-.517 1.641a2 2 0 0 1-.607.607c-.243.155-.557.246-1.64.517L1.756 11.03a1 1 0 0 0 0 1.94l5.206 1.302c1.084.27 1.398.362 1.641.517a2 2 0 0 1 .607.607c.155.243.246.556.517 1.64l1.302 5.206a1 1 0 0 0 1.94 0l1.302-5.206c.27-1.084.362-1.397.517-1.64.156-.244.363-.45.607-.607.243-.155.556-.246 1.64-.517l5.206-1.302a1 1 0 0 0 0-1.94l-5.206-1.302c-1.084-.27-1.397-.362-1.64-.517a2 2 0 0 1-.607-.607c-.155-.243-.246-.557-.517-1.64z"
    />
  </svg>
)
