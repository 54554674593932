/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgEmployeeRelated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgEmployeeRelated"
    {...props}
  >
    <path d="M8 1c-.552 0-1 .449-1 1v1a1 1 0 0 0 2 0V2c0-.551-.448-1-1-1" />
    <circle cx={8} cy={7} r={1.5} fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M5.5 11a2.5 2.5 0 1 1 5 0M5 12.5h6" fill="none" stroke="#000" strokeMiterlimit="10" />
    <path
      d="M11.5 2.5h-7a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-10a1 1 0 0 0-1-1z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
