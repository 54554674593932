/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgQualityControlMissingVendor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 31 31"
    aria-label="SvgQualityControlMissingVendor"
    {...props}
  >
    <filter
      id="quality-control-missing-vendor_svg__a"
      width={20.169}
      height={20.168}
      x={4.854}
      y={4.551}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18953_249951" />
      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18953_249951" result="shape" />
    </filter>
    <path
      fill="#00a69c"
      fillOpacity={0.3}
      fillRule="evenodd"
      d="m17.173 7.405 5.8-.247c1.256-.05 2.227.9 2.185 2.14l-.066 5.932c-.097.515-.26.936-.574 1.322a5.5 5.5 0 0 0-7.662 7.666l-.57.568c-.823.804-2.171.837-2.975.014L7.67 19.13c-.803-.823-.836-2.172-.013-2.975l8.156-8.145c.365-.41.88-.6 1.36-.606m3.222 1.524c.08.59.566 1.066 1.139 1.078.665.03 1.14-.456 1.078-1.139-.08-.59-.566-1.066-1.14-1.078-.59.08-1.065.566-1.077 1.139"
      clipRule="evenodd"
    />
    <circle cx={21.354} cy={21.051} r={4.5} fill="#00a69c" transform="rotate(-90 21.354 21.05)" />
    <path
      fill="#c8fbfc"
      d="M20.877 21.61q0-.204.05-.347a.7.7 0 0 1 .171-.267q.177-.185.322-.32.152-.137.26-.254.106-.123.163-.225a.45.45 0 0 0 .064-.226.45.45 0 0 0-.455-.478q-.297 0-.455.178a.73.73 0 0 0-.17.43l-.973-.068q.069-.725.493-1.1.423-.382 1.086-.382.291 0 .543.089.259.081.455.252.195.17.31.424c.114.254.113.362.113.58q0 .198-.038.362-.038.158-.133.315a1.7 1.7 0 0 1-.24.32q-.144.157-.372.349a.7.7 0 0 0-.196.24.6.6 0 0 0-.05.231v.198h-.948zm-.107 1.312q0-.129.044-.246c.044-.116.07-.143.12-.198a.57.57 0 0 1 .417-.184q.12 0 .227.048a.5.5 0 0 1 .183.136q.082.082.127.198c.045.117.044.16.044.246q0 .13-.044.246c-.044.116-.072.146-.127.205a.55.55 0 0 1-.41.178.6.6 0 0 1-.227-.048.6.6 0 0 1-.19-.13.7.7 0 0 1-.164-.45"
    />
    <g filter="url(#quality-control-missing-vendor_svg__a)">
      <path
        fill="#00a69c"
        fillRule="evenodd"
        d="m16.101 5.84 5.843-.283c1.13-.094 2.167.942 2.073 2.073l-.189 5.936c0 .565-.188 1.037-.66 1.414l-.686.686a5.5 5.5 0 0 0-6.512 6.512l-.905.905c-.754.848-2.073.848-2.921 0L6.49 17.43c-.848-.754-.848-2.073 0-2.921l8.198-8.104c.377-.283.848-.566 1.413-.566m4.24 2.45c0 .659.566 1.13 1.131 1.13s1.131-.377 1.131-1.036-.565-1.131-1.13-1.131c-.66 0-1.131.377-1.131 1.036"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
