/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMessageSquare02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgMessageSquare02Solid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M7.759 2h8.482c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392a4 4 0 0 1 1.748 1.748c.247.485.346 1.002.392 1.564C22 6.29 22 6.954 22 7.758v5.483c0 .805 0 1.47-.044 2.01-.046.563-.145 1.08-.392 1.565a4 4 0 0 1-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044h-2.558c-.664 0-.858.005-1.034.04a2 2 0 0 0-.508.179c-.16.082-.313.199-.832.614l-2.413 1.93c-.184.147-.371.297-.536.406-.157.104-.462.29-.86.29a1.5 1.5 0 0 1-1.173-.563c-.248-.31-.293-.665-.31-.853C6 20.846 6 20.606 6 20.37v-1.38c-.392-.012-.728-.045-1.035-.127a4 4 0 0 1-2.829-2.829C2 15.524 2 14.933 2 14.138V7.759c0-.805 0-1.47.044-2.01.046-.563.145-1.08.392-1.565a4 4 0 0 1 1.748-1.748c.485-.247 1.002-.346 1.564-.392C6.29 2 6.954 2 7.758 2"
      clipRule="evenodd"
    />
  </svg>
)
