/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgShoppingCart03Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgShoppingCart03Solid"
    {...props}
  >
    <g fill="#000">
      <path
        fillRule="evenodd"
        d="M2 3h1.06l.896 14.334c.007.112.016.248.033.367.02.139.06.335.175.538a1.5 1.5 0 0 0 .65.61c.21.102.408.13.548.142.12.01.255.01.368.009H19a1 1 0 1 0 0-2H5.94l-.126-2h12.358c.476 0 .889 0 1.231-.027.364-.028.725-.09 1.08-.256a3 3 0 0 0 1.287-1.152c.204-.334.306-.686.374-1.045.064-.337.11-.748.162-1.22l.592-5.326c.015-.131.032-.285.035-.42a1.5 1.5 0 0 0-.106-.63 1.5 1.5 0 0 0-.66-.737 1.5 1.5 0 0 0-.614-.176C21.418 4 21.263 4 21.131 4H5.127l-.083-1.334a5 5 0 0 0-.033-.367c-.02-.139-.06-.335-.175-.538a1.5 1.5 0 0 0-.65-.61 1.5 1.5 0 0 0-.548-.142C3.518 1 3.383 1 3.271 1H2a1 1 0 0 0 0 2"
        clipRule="evenodd"
      />
      <path d="M7.5 20a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M16.5 20a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3" />
    </g>
  </svg>
)
