/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgInfluencer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgInfluencer"
    {...props}
  >
    <path
      d="M10 10.5h5M10 12.5h5M11.5 14V9M13.5 14V9M9 12.5H2.5a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1V8M4.5 13V3M7.5 13V3M10.5 8V3M1.75 9.5H9M1.75 6.5H13.5"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
