/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDigitsLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="149 259 20 123"
    aria-label="SvgDigitsLogo"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M159.4 259.8c33.5 0 60.6 27.2 60.6 60.7s-27.1 60.7-60.6 60.7-60.6-27.2-60.6-60.7 27.1-60.7 60.6-60.7m35.2 87.6c-1.1 1.3-3.1 1.5-4.4.4s-1.5-3.1-.4-4.4c5-6 8.1-13.8 8.1-22.3 0-14.4-8.7-26.7-21.2-32h-.1c-1.6-.7-2.3-2.6-1.6-4.2s2.6-2.3 4.2-1.6c14.7 6.2 25 20.8 25 37.8 0 10-3.6 19.2-9.6 26.3m-29-60.9c-.8-.1-1.6-.1-2.3-.1h-19.4c-1.7 0-3.1-1.5-3.1-3.2s1.5-3.1 3.2-3.1h19.3c.9 0 1.9 0 2.8.1 1.6.1 2.9 1.4 3 3v.4c-.3 1.8-1.8 3.1-3.5 2.9m15 12.2c.7.6 1.2 1.4 1.2 2.3 0 .8-.2 1.5-.7 2.1-1.1 1.4-3.1 1.6-4.4.5-3.7-2.9-8.4-4.7-13.6-4.7h-36c-1.7 0-3.1-1.5-3.1-3.2s1.5-3.1 3.2-3.1h35.9c6.7.1 12.7 2.4 17.5 6.1m-1.6 22.4c0 8.7-7.1 15.8-15.7 15.8-4 0-8-.2-11.9-.4h-.1c-1.4-.1-2.5-1.1-2.8-2.4-.1-.3-.1-.6-.1-.9v-11.9c0-1.7 1.4-3.2 3.1-3.2h.1c1.8 0 3.2 1.5 3.1 3.2v9c2.9.1 5.8.2 8.6.2 5.3 0 9.4-4.2 9.4-9.5s-4.2-9.5-9.4-9.5h-43.5c-1.7 0-3.1-1.5-3.1-3.2s1.5-3.1 3.2-3.1h43.4c8.6.2 15.7 7.3 15.7 15.9m-41.9 3.2-9.7-.1c-1.7 0-3.1-1.5-3.1-3.2s1.5-3.1 3.2-3.1l9.7.1c1.7 0 3.1 1.4 3.1 3.2v.1c0 1.7-1.5 3.1-3.2 3m-16.8 6.3h16.9c1.7 0 3.2 1.4 3.2 3.1v.1c0 1.7-1.5 3.1-3.2 3.1h-17c-1.7 0-3.1-1.5-3.1-3.2 0-1.8 1.5-3.1 3.2-3.1m7 12.6h36c12.2 0 22-9.8 22-22.1 0-2.6-.4-5.1-1.3-7.4-.6-1.6.3-3.4 1.9-4s3.4.3 4 1.9c1 3 1.6 6.2 1.6 9.5 0 15.6-12.7 28.4-28.3 28.4h-36.1c-1.7 0-3.1-1.5-3.1-3.2.1-1.7 1.5-3.1 3.3-3.1m16.7 12.6h19.3c6 0 11.7-1.5 16.6-4.3.5-.3 1-.4 1.5-.4 1.7 0 3.2 1.4 3.2 3.1 0 1.2-.6 2.3-1.7 2.8-5.8 3.2-12.6 5-19.7 5h-19.3c-1.7 0-3.1-1.5-3.1-3.2 0-1.6 1.4-3 3.2-3"
    />
  </svg>
)
