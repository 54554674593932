/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSearchInvoice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="-4 -5 31 31"
    aria-label="SvgSearchInvoice"
    {...props}
  >
    <path
      fill="#00A69C"
      fillOpacity={0.3}
      d="M5.763 3.074c-.763 0-1.422.569-1.422 1.296v17c0 .695.624 1.295 1.422 1.295h13.912c.764 0 1.423-.568 1.423-1.295V7.782l-5.204-4.708z"
    />
    <path
      fill="#00A69C"
      d="M4.258.621c-.772 0-1.439.609-1.439 1.387v18.197c0 .744.632 1.387 1.439 1.387h14.07c.772 0 1.439-.61 1.439-1.387V5.66L14.504.62z"
    />
    <path
      fill="#000"
      fillOpacity={0.12}
      d="m15.408 6.305 4.324-.677L14.469.555l-.702 4.167c0 .888.72 1.583 1.641 1.583"
    />
    <path fill="#83E2E3" d="M15.907 5.594h3.79L14.468.554v3.654c0 .778.632 1.386 1.439 1.386" />
  </svg>
)
