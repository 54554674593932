/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDetectedMailingAddress = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 32 32"
    aria-label="SvgDetectedMailingAddress"
    {...props}
  >
    <path
      fill="url(#detected-mailing-address_svg__a)"
      d="m3.25 6.5.392.784c.133.266.2.399.288.514a1.5 1.5 0 0 0 .272.272c.115.089.248.155.514.288l.784.392-.784.392c-.266.133-.399.2-.514.288a1.5 1.5 0 0 0-.272.272c-.089.115-.155.248-.288.514L3.25 11l-.392-.784c-.133-.266-.2-.399-.288-.514a1.5 1.5 0 0 0-.272-.272c-.115-.089-.248-.155-.514-.288L1 8.75l.784-.392c.266-.133.399-.2.514-.288a1.5 1.5 0 0 0 .272-.272c.089-.115.155-.248.288-.514z"
    />
    <path
      fill="url(#detected-mailing-address_svg__b)"
      d="m7.5 1 .59 1.532c.14.367.21.55.32.704a1.5 1.5 0 0 0 .354.354c.154.11.337.18.704.32L11 4.5l-1.532.59c-.367.14-.55.21-.704.32a1.5 1.5 0 0 0-.354.354c-.11.154-.18.337-.32.704L7.5 8l-.59-1.532c-.14-.367-.21-.55-.32-.704a1.5 1.5 0 0 0-.354-.354c-.154-.11-.337-.18-.704-.32L4 4.5l1.532-.59c.367-.14.55-.21.704-.32a1.5 1.5 0 0 0 .354-.354c.11-.154.18-.337.32-.704z"
    />
    <path
      fill="url(#detected-mailing-address_svg__c)"
      d="M7.779 12.818c-.25-.179-.375-.268-.484-.28a.42.42 0 0 0-.407.21c-.054.095-.054.247-.054.55v5.403c0 .67 0 1.224.037 1.675.038.469.12.9.326 1.304.32.627.83 1.137 1.457 1.457.404.206.835.288 1.303.326.451.037 1.005.037 1.676.037h8.735c.671 0 1.225 0 1.676-.037.468-.038.899-.12 1.303-.326a3.33 3.33 0 0 0 1.457-1.457c.206-.404.288-.835.327-1.303.036-.451.036-1.005.036-1.676V13.3c0-.149 0-.223-.013-.275a.42.42 0 0 0-.546-.286c-.05.018-.112.061-.236.147l-6.452 4.467c-.458.317-.86.596-1.315.707a2.5 2.5 0 0 1-1.214-.01c-.455-.116-.852-.401-1.305-.726z"
    />
    <path
      fill="url(#detected-mailing-address_svg__d)"
      d="M24.445 10.807c.154-.106.23-.16.278-.245a.47.47 0 0 0 .049-.247c-.01-.098-.052-.162-.135-.29a3.36 3.36 0 0 0-1.29-1.162c-.404-.206-.835-.288-1.303-.326-.451-.037-1.005-.037-1.676-.037h-8.735c-.67 0-1.224 0-1.676.037-.468.038-.899.12-1.303.326-.466.238-.869.58-1.175.998-.096.131-.144.197-.16.297a.47.47 0 0 0 .041.256c.046.09.126.147.286.261L14.96 15.9c.606.432.735.508.848.537a.83.83 0 0 0 .405.003c.114-.028.244-.101.856-.525z"
    />
    <defs>
      <linearGradient
        id="detected-mailing-address_svg__a"
        x1={0.86}
        x2={11.648}
        y1={14.12}
        y2={13.059}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C92BE" stopOpacity={0.8} />
        <stop offset={1} stopColor="#3D3AC2" stopOpacity={0.54} />
      </linearGradient>
      <linearGradient
        id="detected-mailing-address_svg__b"
        x1={0.86}
        x2={11.648}
        y1={14.12}
        y2={13.059}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C92BE" stopOpacity={0.8} />
        <stop offset={1} stopColor="#3D3AC2" stopOpacity={0.54} />
      </linearGradient>
      <linearGradient
        id="detected-mailing-address_svg__c"
        x1={6.577}
        x2={26.262}
        y1={28.18}
        y2={25.813}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C92BE" stopOpacity={0.8} />
        <stop offset={1} stopColor="#3D3AC2" stopOpacity={0.54} />
      </linearGradient>
      <linearGradient
        id="detected-mailing-address_svg__d"
        x1={6.577}
        x2={26.262}
        y1={28.18}
        y2={25.813}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C92BE" stopOpacity={0.8} />
        <stop offset={1} stopColor="#3D3AC2" stopOpacity={0.54} />
      </linearGradient>
    </defs>
  </svg>
)
