/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgEmployerTax = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgEmployerTax"
    {...props}
  >
    <path
      d="M2 12.5a.5.5 0 0 1 .5.5M13 12.5a.5.5 0 0 0-.5.5M2 7.5a.5.5 0 0 0 .5-.5M13 7.5a.5.5 0 0 1-.5-.5"
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
    <circle cx={4} cy={10} r={1} />
    <circle cx={11} cy={10} r={1} />
    <circle cx={5.5} cy={2.5} r={1} fill="none" stroke="#000" strokeMiterlimit="10" />
    <circle cx={9.5} cy={2.5} r={1} fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="m6 3 1.606 2.249" fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M5 7.739v1.722l4.407-6.17-.814-.582z" />
    <path d="M12.5 6H8.701l-.714 1H12.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5h1.799l.714-1H2.5C1.673 6 1 6.673 1 7.5v5c0 .827.673 1.5 1.5 1.5h10c.827 0 1.5-.673 1.5-1.5v-5c0-.827-.673-1.5-1.5-1.5" />
    <path d="M7.272 8H8V7h-.013z" />
    <path d="M7.5 9v2M7.5 12v2" fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M6 10c0 .87.419 1.602 1 1.878V8.381l-.968 1.356C6.022 9.826 6 9.908 6 10M8 8.122v3.755c.581-.275 1-1.007 1-1.877s-.419-1.602-1-1.878" />
  </svg>
)
