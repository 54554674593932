/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgVision = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgVision"
    {...props}
  >
    <path
      d="M4 12.5A2.507 2.507 0 0 1 1.5 10v-.5c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v.5c0 1.375-1.125 2.5-2.5 2.5zM11 12.5A2.507 2.507 0 0 1 8.5 10v-.5c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v.5c0 1.375-1.125 2.5-2.5 2.5zM5.5 8.5h4"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M1.5 9.625V3.5c0-.55.45-1 1-1s1 .45 1 1V4M13.5 9.625V3.5c0-.55-.45-1-1-1s-1 .45-1 1V4"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
