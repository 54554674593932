/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBellRinging01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgBellRinging01Solid"
    {...props}
  >
    <g fill="#000" fillRule="evenodd" clipRule="evenodd">
      <path d="M8.604 20.338a1 1 0 0 1 1.412-.088c.53.468 1.223.75 1.984.75s1.455-.282 1.984-.75a1 1 0 1 1 1.324 1.5C14.427 22.527 13.268 23 12 23s-2.427-.473-3.308-1.25a1 1 0 0 1-.088-1.412M5.197 1.809a1 1 0 0 1-.38 1.362A3.01 3.01 0 0 0 3.295 5.81a1 1 0 0 1-2 .02 5.01 5.01 0 0 1 2.54-4.4 1 1 0 0 1 1.363.379M18.799 1.809a1 1 0 0 1 1.362-.38 5.01 5.01 0 0 1 2.541 4.4 1 1 0 0 1-2-.019 3.01 3.01 0 0 0-1.523-2.64 1 1 0 0 1-.38-1.361M7.05 3.05A7 7 0 0 1 19 8c0 2.913.732 4.844 1.5 6.077l.011.02c.358.573.64 1.027.83 1.358.096.165.183.325.247.466.032.071.068.158.096.252.024.078.068.246.051.45-.01.136-.039.37-.173.611a1.5 1.5 0 0 1-.428.47c-.25.186-.538.229-.634.243h-.004a5 5 0 0 1-.483.04c-.336.013-.8.013-1.377.013H5.364c-.577 0-1.041 0-1.376-.013a5 5 0 0 1-.484-.04H3.5a1.4 1.4 0 0 1-.634-.242c-.11-.082-.294-.23-.428-.47s-.162-.476-.173-.613c-.017-.203.027-.37.05-.449.029-.094.065-.18.097-.252a6 6 0 0 1 .247-.466c.19-.33.473-.785.83-1.359l.012-.019C4.268 12.844 5 10.913 5 8a7 7 0 0 1 2.05-4.95" />
    </g>
  </svg>
)
