/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgGetPaidCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 57 56"
    aria-label="SvgGetPaidCheckIcon"
    {...props}
  >
    <g clipPath="url(#get-paid-check-icon_svg__a)">
      <rect width={57} height={56} fill="url(#get-paid-check-icon_svg__b)" rx={10} />
      <g filter="url(#get-paid-check-icon_svg__c)" shapeRendering="crispEdges">
        <rect
          width={37}
          height={24}
          x={10}
          y={16}
          fill="url(#get-paid-check-icon_svg__d)"
          fillOpacity={0.7}
          rx={2}
        />
        <rect width={38} height={25} x={9.5} y={15.5} stroke="#fff" strokeOpacity={0.5} rx={2.5} />
      </g>
      <g filter="url(#get-paid-check-icon_svg__e)">
        <rect
          width={23}
          height={4}
          x={12}
          y={23}
          fill="url(#get-paid-check-icon_svg__f)"
          fillOpacity={0.8}
          rx={1}
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#get-paid-check-icon_svg__g)">
        <rect
          width={13}
          height={2}
          x={12}
          y={31}
          fill="url(#get-paid-check-icon_svg__h)"
          fillOpacity={0.8}
          rx={1}
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#get-paid-check-icon_svg__i)">
        <rect
          width={5}
          height={4}
          x={40}
          y={23}
          fill="url(#get-paid-check-icon_svg__j)"
          fillOpacity={0.8}
          rx={1}
          shapeRendering="crispEdges"
        />
      </g>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={2}
        d="M31 38.73c1.782 0 4.079-4.09 5.672-5.506.356-.316 2.248-1.821 2.522-.786.278 1.048.164 2.289-.026 3.333-.097.537-.702 2.057-.605 1.52.13-.715.934-1.499 1.34-2.055.793-1.088 1.616-2.257 2.763-3.005.607-.397 1.14-.31 1.157.483.015.662.074 1.387-.008 2.047-.044.348 0 1.174.685 1.47 1 .5 4.039-2.27 4.5-2.5"
      />
    </g>
    <defs>
      <linearGradient
        id="get-paid-check-icon_svg__b"
        x1={3.094}
        x2={54.645}
        y1={5.111}
        y2={45.285}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B95DD9" />
        <stop offset={1} stopColor="#3C9ACF" />
      </linearGradient>
      <linearGradient
        id="get-paid-check-icon_svg__d"
        x1={28.501}
        x2={28.501}
        y1={15.57}
        y2={39.581}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0.35} />
      </linearGradient>
      <linearGradient
        id="get-paid-check-icon_svg__f"
        x1={23.501}
        x2={23.501}
        y1={22.928}
        y2={26.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.422} stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="get-paid-check-icon_svg__h"
        x1={18.5}
        x2={18.5}
        y1={30.964}
        y2={32.965}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.422} stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="get-paid-check-icon_svg__j"
        x1={42.5}
        x2={42.5}
        y1={22.928}
        y2={26.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.422} stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <filter
        id="get-paid-check-icon_svg__c"
        width={45.545}
        height={33.364}
        x={5.727}
        y={12.546}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.227} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2_41" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1.636} />
        <feGaussianBlur stdDeviation={1.636} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend in2="effect1_backgroundBlur_2_41" result="effect2_dropShadow_2_41" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_2_41" result="shape" />
      </filter>
      <filter
        id="get-paid-check-icon_svg__e"
        width={29.546}
        height={12.367}
        x={8.727}
        y={20.546}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.227} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2_41" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2.64} />
        <feGaussianBlur stdDeviation={1.636} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.580392 0 0 0 0 0.439216 0 0 0 0 0.843137 0 0 0 0.5 0" />
        <feBlend in2="effect1_backgroundBlur_2_41" result="effect2_dropShadow_2_41" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_2_41" result="shape" />
      </filter>
      <filter
        id="get-paid-check-icon_svg__g"
        width={19.546}
        height={10.367}
        x={8.727}
        y={28.546}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.227} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2_41" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2.64} />
        <feGaussianBlur stdDeviation={1.636} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.580392 0 0 0 0 0.439216 0 0 0 0 0.843137 0 0 0 0.5 0" />
        <feBlend in2="effect1_backgroundBlur_2_41" result="effect2_dropShadow_2_41" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_2_41" result="shape" />
      </filter>
      <filter
        id="get-paid-check-icon_svg__i"
        width={11.546}
        height={12.367}
        x={36.727}
        y={20.546}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.227} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2_41" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2.64} />
        <feGaussianBlur stdDeviation={1.636} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.580392 0 0 0 0 0.439216 0 0 0 0 0.843137 0 0 0 0.5 0" />
        <feBlend in2="effect1_backgroundBlur_2_41" result="effect2_dropShadow_2_41" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_2_41" result="shape" />
      </filter>
      <clipPath id="get-paid-check-icon_svg__a">
        <rect width={57} height={56} fill="#fff" rx={10} />
      </clipPath>
    </defs>
  </svg>
)
