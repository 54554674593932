/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgUserSquareSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgUserSquareSolid"
    {...props}
  >
    <g fill="#000" fillRule="evenodd" clipRule="evenodd">
      <path d="M7 9.5a5 5 0 1 1 10 0 5 5 0 0 1-10 0" />
      <path d="M17.241 1H6.76c-.805 0-1.47 0-2.01.044-.563.046-1.08.145-1.565.392a4 4 0 0 0-1.748 1.748c-.247.485-.346 1.002-.392 1.564C1 5.29 1 5.954 1 6.758v10.483c0 .805 0 1.47.044 2.01.046.563.145 1.08.392 1.565a4 4 0 0 0 1.748 1.748c.485.247 1.002.346 1.564.392C5.29 23 5.954 23 6.758 23h10.483c.805 0 1.47 0 2.01-.044.563-.046 1.08-.145 1.565-.392a4 4 0 0 0 1.748-1.748c.247-.485.346-1.002.392-1.564.044-.541.044-1.206.044-2.01V6.758c0-.805 0-1.47-.044-2.01-.046-.563-.145-1.08-.392-1.565a4 4 0 0 0-1.748-1.748c-.485-.247-1.002-.346-1.564-.392C18.71 1 18.046 1 17.242 1M4.092 3.218c.156-.08.38-.145.819-.18C5.361 3 5.943 3 6.8 3h10.4c.857 0 1.439 0 1.889.038.438.035.663.1.819.18a2 2 0 0 1 .874.874c.08.156.145.38.18.819C21 5.361 21 5.943 21 6.8v10.4c0 .857 0 1.439-.038 1.889a2.8 2.8 0 0 1-.118.671 5 5 0 0 0-4.354-3.736C16.245 16 15.966 16 15.558 16H8.442c-.408 0-.687 0-.932.024a5 5 0 0 0-4.354 3.736 2.8 2.8 0 0 1-.118-.671C3 18.639 3 18.057 3 17.2V6.8c0-.857 0-1.439.038-1.889.035-.438.1-.663.18-.819a2 2 0 0 1 .874-.874" />
    </g>
  </svg>
)
