/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAdvertising = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgAdvertising"
    {...props}
  >
    <path
      d="M5.49.994a.5.5 0 0 0-.375.826L6.932 4H1.5a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5H9.068l1.817-2.18a.5.5 0 1 0-.77-.64L8 3.719l-2.115-2.54A.5.5 0 0 0 5.49.995M2 5h12v8H2zm2.5 1C3.677 6 3 6.677 3 7.5v3c0 .823.677 1.5 1.5 1.5h5c.823 0 1.5-.677 1.5-1.5v-3c0-.823-.677-1.5-1.5-1.5zm8 0a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5m-8 1h5c.281 0 .5.219.5.5v3c0 .281-.219.5-.5.5h-5a.493.493 0 0 1-.5-.5v-3c0-.281.219-.5.5-.5m8 1a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5m0 2a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5"
      fontFamily="sans-serif"
      fontWeight={400}
      overflow="visible"
    />
  </svg>
)
