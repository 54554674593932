/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFixedAssets = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgFixedAssets"
    {...props}
  >
    <circle cx={7.5} cy={7.5} r={6} fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M7.5 1.5v6h-6M11.625 11.625 7.5 7.5" fill="none" stroke="#000" strokeMiterlimit="10" />
    <path d="M9 7h5.975a7.47 7.47 0 0 0-1.977-4.59z" />
  </svg>
)
