/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSecurity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgSecurity"
    {...props}
  >
    <path
      d="M8 14.5s-5.5-3.454-5.5-8V3.268c0-.458.308-.856.752-.969C4.395 2.008 6.586 1.5 8 1.5s3.605.508 4.748.799a1 1 0 0 1 .752.969V6.5c0 4.462-5.5 8-5.5 8z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path d="M12 4.625 7.375 9.25 4.75 6.625" fill="none" stroke="#000" strokeMiterlimit="10" />
  </svg>
)
