/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgHosting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgHosting"
    {...props}
  >
    <path d="M8 2C6.168 2 4.574 3.273 4.133 5.023c-1.078.16-1.934 1.02-2.102 2.137A3.01 3.01 0 0 0 0 10c0 1.652 1.348 3 3 3h3v-1H3c-1.102 0-2-.898-2-2 0-.95.676-1.773 1.602-1.96l.41-.118L3 7.5c0-.828.672-1.5 1.45-1.504l.1.012h.434l.063-.434A3 3 0 0 1 8 3c1.207 0 2.293.719 2.766 1.828l.175.43.438-.149c.226-.074.43-.109.621-.109 1.102 0 2 .898 2 2q0 .353-.14.71l-.141.384.344.219c.585.37.937 1 .937 1.687 0 1.102-.898 2-2 2H9v1h4c1.652 0 3-1.348 3-3 0-.898-.398-1.738-1.086-2.305.059-.23.086-.465.086-.695 0-1.82-1.61-3.305-3.508-2.953A4 4 0 0 0 8 2m-.5 6L5 11h2v4h1v-4h2Z" />
  </svg>
)
