/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgElectrical01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgElectrical01"
    {...props}
  >
    <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="m18.027 11.836.766.778c.429.428.442 1.108.031 1.52-.411.41-1.091.397-1.52-.031l-.22-.221-1.171 1.17c-1.616 1.616-4.17 1.861-6.17.632l-4.15 4.15a.306.306 0 0 1-.433-.008l-1.33-1.33a.306.306 0 0 1-.008-.432l4.15-4.15c-1.229-2-.984-4.555.632-6.17l1.17-1.171-.22-.221c-.429-.428-.442-1.108-.03-1.52.41-.41 1.09-.397 1.519.031l2.325 2.325 3.1 3.1-.003.002zM13.074 6.504l4.093-4.093M17.262 10.51l4.092-4.093" />
    </g>
  </svg>
)
