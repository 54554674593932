/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgHealth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgHealth"
    {...props}
  >
    <path
      d="M0 8.5h1.945M3.207 11.207A1 1 0 0 0 3.5 10.5v-4a1 1 0 1 0-2 0v4a1 1 0 0 0 1.707.707zM5.207 13.207A1 1 0 0 0 5.5 12.5v-8a1 1 0 1 0-2 0v8a1 1 0 0 0 1.707.707zM5.5 8.5h5M14.055 8.5H16M12.793 11.207a1 1 0 0 1-.293-.707v-4a1 1 0 1 1 2 0v4a1 1 0 0 1-1.707.707zM10.793 13.207a1 1 0 0 1-.293-.707v-8a1 1 0 1 1 2 0v8a1 1 0 0 1-1.707.707z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
