/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBell01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgBell01Solid"
    {...props}
  >
    <g fill="#000" fillRule="evenodd" clipRule="evenodd">
      <path d="M8.605 20.338a1 1 0 0 1 1.411-.088c.53.468 1.223.75 1.984.75s1.455-.282 1.984-.75a1 1 0 1 1 1.324 1.5A4.98 4.98 0 0 1 12 23a4.99 4.99 0 0 1-3.307-1.25 1 1 0 0 1-.088-1.412M7.05 3.05A7 7 0 0 1 19 8c0 2.913.732 4.844 1.5 6.077l.012.02c.357.573.64 1.027.83 1.358.095.165.182.325.246.466.033.071.068.158.097.252.023.078.067.246.05.45-.01.136-.038.37-.173.611a1.5 1.5 0 0 1-.428.47c-.25.186-.537.229-.633.243h-.005a5 5 0 0 1-.483.04c-.336.013-.8.013-1.377.013H5.364c-.577 0-1.041 0-1.376-.013a5 5 0 0 1-.484-.04H3.5a1.4 1.4 0 0 1-.634-.242 1.5 1.5 0 0 1-.428-.47 1.5 1.5 0 0 1-.173-.613c-.017-.203.028-.37.05-.449.029-.094.065-.18.097-.252a6 6 0 0 1 .247-.466c.19-.33.473-.785.83-1.359l.012-.019C4.268 12.844 5 10.913 5 8a7 7 0 0 1 2.05-4.95" />
    </g>
  </svg>
)
