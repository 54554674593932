/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgUsersEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgUsersEdit"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11 15H8c-1.864 0-2.796 0-3.53.305a4 4 0 0 0-2.166 2.164C2 18.204 2 19.136 2 21M15.5 3.29A4 4 0 0 1 18 7m-6 14.5 2.025-.405c.177-.035.265-.053.347-.085a1 1 0 0 0 .207-.111c.073-.05.136-.114.264-.242L21.5 14a1.414 1.414 0 1 0-2-2l-6.657 6.658c-.128.127-.191.19-.242.263a1 1 0 0 0-.11.207c-.033.082-.05.17-.086.347zM13.5 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0"
    />
  </svg>
)
