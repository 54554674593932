/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgHourglass01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgHourglass01Solid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M3 2a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2h-1v1.867c0 .714 0 1.254-.137 1.767a4 4 0 0 1-.592 1.263c-.306.435-.72.78-1.27 1.237L13.563 12l3.44 2.866c.549.457.963.802 1.27 1.237a4 4 0 0 1 .59 1.263c.139.513.139 1.053.138 1.767V21h1a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2h1v-1.867c0-.714 0-1.254.137-1.767a4 4 0 0 1 .592-1.263c.306-.435.72-.78 1.27-1.237L10.437 12l-3.44-2.866c-.549-.457-.963-.802-1.27-1.237a4 4 0 0 1-.59-1.263C4.998 6.121 5 5.581 5 4.867V3H4a1 1 0 0 1-1-1m4 2.752V3h10v1.752c0 .88-.008 1.137-.069 1.362a2 2 0 0 1-.295.632c-.135.19-.327.361-1.003.925L12 10.698 8.367 7.671c-.676-.564-.868-.734-1.003-.925a2 2 0 0 1-.295-.632C7.009 5.89 7 5.632 7 4.752"
      clipRule="evenodd"
    />
  </svg>
)
