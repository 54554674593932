/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgProductEngineering = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgProductEngineering"
    {...props}
  >
    <path
      d="M13.512 8c0-.563-.085-1.106-.242-1.617l.879-.754-1.015-1.758-1.099.387a5.5 5.5 0 0 0-2.793-1.615L9.027 1.5h-2.03l-.215 1.143a5.5 5.5 0 0 0-2.793 1.615L2.89 3.871 1.875 5.629l.879.754a5.5 5.5 0 0 0 0 3.234l-.879.754 1.015 1.758 1.099-.387a5.5 5.5 0 0 0 2.793 1.615l.215 1.143h2.03l.214-1.143a5.5 5.5 0 0 0 2.793-1.615l1.099.387 1.015-1.758-.879-.754A5.5 5.5 0 0 0 13.512 8z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle cx={8.012} cy={8} r={3.5} fill="none" stroke="#000" strokeMiterlimit="10" />
    <circle cx={8.012} cy={8} r={1} />
  </svg>
)
