/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAnalysisDimensionList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 22 22"
    aria-label="SvgAnalysisDimensionList"
    {...props}
  >
    <path
      stroke="url(#analysis-dimension-list_svg__a)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.5}
      d="M10 16h9"
    />
    <path
      stroke="url(#analysis-dimension-list_svg__b)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.5}
      d="M4 16h1"
    />
    <path
      stroke="url(#analysis-dimension-list_svg__c)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.5}
      d="M10 11h9"
    />
    <path
      stroke="url(#analysis-dimension-list_svg__d)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.5}
      d="M4 11h1"
    />
    <path
      stroke="url(#analysis-dimension-list_svg__e)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.5}
      d="M10 6h9"
    />
    <path
      stroke="url(#analysis-dimension-list_svg__f)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.5}
      d="M4 6h1"
    />
    <defs>
      <linearGradient
        id="analysis-dimension-list_svg__a"
        x1={15.425}
        x2={15.24}
        y1={15.019}
        y2={13.794}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#02BEB9" />
        <stop offset={1} stopColor="#208095" />
      </linearGradient>
      <linearGradient
        id="analysis-dimension-list_svg__b"
        x1={4.603}
        x2={4.004}
        y1={15.019}
        y2={14.578}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#02BEB9" />
        <stop offset={1} stopColor="#208095" />
      </linearGradient>
      <linearGradient
        id="analysis-dimension-list_svg__c"
        x1={14.804}
        x2={6.498}
        y1={-2157090000000}
        y2={-2157090000000}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2ACDC9" />
        <stop offset={1} stopColor="#208095" />
      </linearGradient>
      <linearGradient
        id="analysis-dimension-list_svg__d"
        x1={4.534}
        x2={3.611}
        y1={-2157090000000}
        y2={-2157090000000}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2ACDC9" />
        <stop offset={1} stopColor="#208095" />
      </linearGradient>
      <linearGradient
        id="analysis-dimension-list_svg__e"
        x1={14.184}
        x2={13.999}
        y1={5.019}
        y2={3.794}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6DEDEA" />
        <stop offset={1} stopColor="#208095" />
      </linearGradient>
      <linearGradient
        id="analysis-dimension-list_svg__f"
        x1={4.465}
        x2={3.866}
        y1={5.019}
        y2={4.578}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6DEDEA" />
        <stop offset={1} stopColor="#208095" />
      </linearGradient>
    </defs>
  </svg>
)
