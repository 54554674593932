/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgInternet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgInternet"
    {...props}
  >
    <path d="M14 8v-.5c0-1.267-.37-2.447-1-3.448V4h-.032C11.812 2.199 9.795 1 7.5 1S3.188 2.199 2.032 4H2v.052a6.45 6.45 0 0 0 0 6.896V11h.032A6.5 6.5 0 0 0 7.5 14H8V8zM2.022 8h1.991c.028.706.112 1.379.247 2H2.606a5.5 5.5 0 0 1-.584-2m0-1c.065-.715.27-1.39.583-2H4.26c-.136.621-.22 1.294-.247 2zm3.275-2H7v2H5.022c.031-.729.127-1.401.275-2m4.099-1H8V2.129c.519.264 1.023.924 1.396 1.871M7 2.129V4H5.604c.373-.947.877-1.607 1.396-1.871M8 5h1.703c.148.599.243 1.271.275 2H8zm2.987 2a11.6 11.6 0 0 0-.247-2h1.655c.313.61.519 1.285.583 2zm.731-3h-1.257a6.9 6.9 0 0 0-.699-1.494A5.5 5.5 0 0 1 11.718 4m-6.48-1.493A6.7 6.7 0 0 0 4.539 4H3.282a5.5 5.5 0 0 1 1.956-1.493M3.282 11h1.257c.191.564.426 1.068.699 1.493A5.5 5.5 0 0 1 3.282 11m2.322 0H7v1.871c-.519-.264-1.023-.924-1.396-1.871M7 10H5.297a10 10 0 0 1-.275-2H7z" />
    <path d="M12.854 11.511 14 10.5 9 9l2 5 .94-1.344L13.5 14l1-1.122z" />
  </svg>
)
