/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFreight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgFreight"
    {...props}
  >
    <path
      d="M12.22 11.45s2.018-.95 2.268-3.95c.029-.351.012-2 .012-2h-4l-1 2h-8l2.06 3.74"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      d="M3.5 3.5V2M3.5 11a1.5 1.5 0 0 1-3 0M6.5 11a1.5 1.5 0 0 1-3 0M9.5 11a1.5 1.5 0 0 1-3 0M12.5 11a1.5 1.5 0 0 1-3 0M15.5 11a1.5 1.5 0 0 1-3 0"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M4.5 7.5v-2l1-1v-1h-3v4"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <circle cx={12.5} cy={7.5} r={0.5} />
  </svg>
)
