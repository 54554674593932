/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPiggyBank02 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgPiggyBank02"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5 13a6 6 0 0 1 6-6m-6 6c0 1.648.665 3.142 1.74 4.226.104.105.156.157.187.207.03.048.046.09.06.144.013.057.013.122.013.253v2.37c0 .28 0 .42.054.527a.5.5 0 0 0 .219.219C7.38 21 7.52 21 7.8 21h1.9c.28 0 .42 0 .527-.054a.5.5 0 0 0 .218-.219c.055-.107.055-.247.055-.527v-.4c0-.28 0-.42.054-.527a.5.5 0 0 1 .219-.218C10.88 19 11.02 19 11.3 19h1.4c.28 0 .42 0 .527.055a.5.5 0 0 1 .218.218c.055.107.055.247.055.527v.4c0 .28 0 .42.054.527a.5.5 0 0 0 .219.219c.107.054.247.054.527.054h1.9c.28 0 .42 0 .527-.054a.5.5 0 0 0 .219-.219C17 20.62 17 20.48 17 20.2v-.976c0-.202 0-.303.029-.384a.46.46 0 0 1 .121-.19c.061-.06.166-.108.374-.205a6 6 0 0 0 2.427-2.055c.107-.155.16-.233.217-.28a.5.5 0 0 1 .17-.089c.07-.021.154-.021.32-.021h.542c.28 0 .42 0 .527-.055a.5.5 0 0 0 .219-.218C22 15.62 22 15.48 22 15.2v-3.414c0-.267 0-.4-.05-.503a.5.5 0 0 0-.233-.234C21.614 11 21.481 11 21.214 11c-.193 0-.29 0-.367-.026a.46.46 0 0 1-.203-.13c-.057-.06-.103-.16-.195-.359a6 6 0 0 0-1.19-1.711c-.103-.105-.155-.157-.186-.207a.5.5 0 0 1-.06-.144C19 8.366 19 8.3 19 8.17V7.06c0-.36 0-.54-.075-.66a.5.5 0 0 0-.288-.218c-.137-.039-.31.01-.657.11l-2.372.677a.498.498 0 0 1-.136.029C15.45 7 15.43 7 15.387 7H11m-6 6H4a2 2 0 0 1-1-3.732M11 7h3.965Q15 6.755 15 6.5a3.5 3.5 0 1 0-6.793 1.188A6 6 0 0 1 11 7"
    />
  </svg>
)
