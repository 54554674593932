/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgStar06Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgStar06Solid"
    {...props}
  >
    <g fill="#000">
      <path d="M5.5 2a1 1 0 0 0-2 0v1.5H2a1 1 0 0 0 0 2h1.5V7a1 1 0 0 0 2 0V5.5H7a1 1 0 0 0 0-2H5.5zM5.5 17a1 1 0 1 0-2 0v1.5H2a1 1 0 1 0 0 2h1.5V22a1 1 0 1 0 2 0v-1.5H7a1 1 0 1 0 0-2H5.5zM13.933 2.641a1 1 0 0 0-1.866 0L10.333 7.15c-.3.78-.395 1.006-.524 1.188a2 2 0 0 1-.471.47c-.182.13-.407.224-1.188.524L3.64 11.067a1 1 0 0 0 0 1.866l4.509 1.734c.78.3 1.006.395 1.188.524.182.13.341.29.47.471.13.182.224.407.525 1.188l1.734 4.509a1 1 0 0 0 1.866 0l1.734-4.509c.3-.78.395-1.006.524-1.188.13-.182.29-.341.471-.47.182-.13.407-.224 1.188-.524l4.509-1.735a1 1 0 0 0 0-1.866L17.85 9.332c-.78-.3-1.006-.394-1.188-.523a2 2 0 0 1-.47-.471c-.13-.182-.224-.407-.524-1.188z" />
    </g>
  </svg>
)
