/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMerge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 22 22"
    aria-label="SvgMerge"
    {...props}
  >
    <path
      fill="url(#merge_svg__a)"
      d="m5.609 16.84-1.649-.717c.981-2.254 2.266-3.95 3.792-4.953-1.526-1.003-2.81-2.699-3.792-4.953L5.61 5.5c.947 2.177 2.59 4.627 5.538 4.627h2.156v-2.19l5.03 3.233-5.03 3.234v-2.191h-2.156c-2.948 0-4.59 2.45-5.538 4.627"
    />
    <defs>
      <linearGradient
        id="merge_svg__a"
        x1={18.334}
        x2={3.398}
        y1={6.74}
        y2={7.653}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6B92D5" />
        <stop offset={1} stopColor="#6CB1CB" />
      </linearGradient>
    </defs>
  </svg>
)
