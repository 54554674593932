/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCurrencyDollarCircleSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgCurrencyDollarCircleSolid"
    {...props}
  >
    <path
      fill="#32497f"
      fillRule="evenodd"
      d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1m1 4.5a1 1 0 1 0-2 0V6a3.5 3.5 0 1 0 0 7h2a1.5 1.5 0 0 1 0 3h-2.167A1.333 1.333 0 0 1 9.5 14.667a1 1 0 1 0-2 0A3.333 3.333 0 0 0 10.833 18H11v.5a1 1 0 1 0 2 0V18a3.5 3.5 0 1 0 0-7h-2a1.5 1.5 0 0 1 0-3h2.167c.736 0 1.333.597 1.333 1.333a1 1 0 1 0 2 0A3.333 3.333 0 0 0 13.167 6H13z"
      clipRule="evenodd"
    />
  </svg>
)
