/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCurrentLiabilities = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 24.222 16.201"
    aria-label="SvgCurrentLiabilities"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    <path d="M4.999 11.869c-.119.12-.237.236-.473.236s-.355-.116-.473-.236c-.119-.118-.236-.234-.236-.472V5.252c0-.238.118-.355.236-.472.118-.12.237-.237.473-.237.118 0 .354.117.473.237.117.116.236.233.236.472v6.146c0 .237-.119.353-.236.471M13.154 10.333c-.355.592-.709 1.064-1.3 1.418s-1.182.474-1.891.474-1.3-.119-1.891-.474c-.591-.354-.945-.826-1.3-1.418-.355-.59-.473-1.182-.473-1.891 0-.708.118-1.298.473-1.89.354-.591.709-1.064 1.3-1.419.591-.353 1.181-.473 1.89-.473s1.3.12 1.891.474.945.828 1.3 1.419c.354.592.471 1.182.471 1.89.001.59-.117 1.3-.47 1.89m-1.182-3.188c-.237-.356-.473-.713-.829-.947a2 2 0 0 0-1.181-.355c-.473 0-.827.12-1.182.355-.354.234-.591.471-.826.947-.237.352-.237.825-.237 1.297 0 .474.118.944.236 1.301.236.355.473.709.827.944.355.237.709.355 1.182.355s.827-.118 1.181-.355c.356-.235.591-.473.829-.944.236-.356.236-.827.236-1.301 0-.591-.119-.945-.236-1.297M20.599 4.78c.117.116.117.233.117.472v3.781c0 .592-.117 1.065-.354 1.536a2.32 2.32 0 0 1-1.064 1.065c-.473.234-.945.354-1.536.354s-1.063-.12-1.536-.354a2.32 2.32 0 0 1-1.064-1.065 3.27 3.27 0 0 1-.354-1.536V5.252c0-.238.118-.355.237-.472.117-.12.236-.237.473-.237.119 0 .355.117.473.237.117.116.236.233.236.472v3.781c0 .354.117.592.236.944.117.237.354.475.59.592.238.118.473.237.829.237.354 0 .591-.119.826-.237a1.4 1.4 0 0 0 .591-.592c.119-.234.236-.591.236-.944V5.252c0-.238 0-.355.119-.472.117-.12.235-.237.473-.237.117.117.353.117.472.237" />
    <path d="M3.34 0C1.499 0 0 1.5 0 3.34v9.52a3.346 3.346 0 0 0 3.34 3.342h11.794c.41 0 .75-.342.75-.75 0-.41-.342-.75-.75-.75H3.34a1.76 1.76 0 0 1-1.772-1.771V3.408A1.76 1.76 0 0 1 3.34 1.637h17.542a1.76 1.76 0 0 1 1.771 1.771v9.521a1.76 1.76 0 0 1-1.771 1.771H9.132c-.409 0-.75.34-.75.75 0 .408.341.75.75.75h11.75c1.84 0 3.34-1.5 3.34-3.342V3.34c0-1.84-1.5-3.34-3.34-3.34z" />
  </svg>
)
