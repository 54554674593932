/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAccountsReceivable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgAccountsReceivable"
    {...props}
  >
    <path
      d="M10.5 10.5c-2.5 0-2.25-.125-4-1.5-.564-.443-1-.5-1.5-.5-.451 0-1.03.135-1.03.135L1.5 9.5l1 3 2.5-1 3.375 1.665a2 2 0 0 0 1.966.141L14.5 10.5"
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      d="M11.5 0v5.5a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V0M14.5 0v3.5a1 1 0 0 1-1 1h-2"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M6.5 6a.5.5 0 0 0-.5-.5M10.5 6a.5.5 0 0 1 .5-.5"
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit="10"
    />
    <circle cx={8.5} cy={4.5} r={0.5} />
    <circle cx={8.5} cy={1.5} r={1} fill="none" stroke="#000" strokeMiterlimit="10" />
  </svg>
)
