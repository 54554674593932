/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgRepairsMaintenance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgRepairsMaintenance"
    {...props}
  >
    <path d="M10 1C7.797 1 6 2.797 6 5c0 .482.154.912.31 1.334l-2.677 2.68C3.586 9.01 3.552 9 3.5 9A2.507 2.507 0 0 0 1 11.5C1 12.875 2.125 14 3.5 14S6 12.875 6 11.5c0-.05-.008-.08-.012-.125L8.67 8.691C9.09 8.847 9.519 9 10 9c2.203 0 4-1.797 4-4a4 4 0 0 0-.275-1.437l-.278-.72-.543.546L11.293 5H10V3.707l2.156-2.154-.719-.278A4 4 0 0 0 10 1m0 1c.086 0 .162.04.246.047L9 3.293V6h2.707l1.246-1.246c.008.084.047.16.047.246 0 1.663-1.337 3-3 3-.456 0-.886-.11-1.283-.299l-.319-.152-3.462 3.465.039.252q.024.146.025.234c0 .835-.665 1.5-1.5 1.5S2 12.335 2 11.5 2.665 10 3.5 10c.065 0 .142.01.242.025l.254.041 3.457-3.46-.152-.32C7.11 5.885 7 5.456 7 5c0-1.663 1.337-3 3-3m-6.5 9a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5" />
  </svg>
)
