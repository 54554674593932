/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgRentLease = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgRentLease"
    {...props}
  >
    <path
      d="M6 2c-1.099 0-2 .901-2 2s.901 2 2 2c.733 0 1.37-.406 1.719-1H13V3h-1v1h-1V3h-1v1H8c0-1.099-.901-2-2-2m0 1c.558 0 1 .442 1 1s-.442 1-1 1-1-.442-1-1 .442-1 1-1M5 8.002c-.55 0-1.143.146-1.143.146l-.052.016-2.471.865a.5.5 0 0 0-.309.63l1 3a.5.5 0 0 0 .66.306l2.294-.916 3.175 1.566-.056-.033c.73.486 1.664.553 2.457.178a1 1 0 0 0 .066-.04l4.158-2.804.414-.28-.558-.83-.414.28-4.104 2.77a1.5 1.5 0 0 1-1.465-.106 1 1 0 0 0-.056-.033L5.22 11.053a.5.5 0 0 0-.407-.016l-2.007.803-.676-2.03 1.963-.689c.012-.002.559-.119.906-.119.462 0 .71.014 1.191.393.86.675 1.243 1.076 1.829 1.33.585.253 1.222.277 2.48.277h.5v-1h-.5c-1.242 0-1.73-.04-2.082-.193s-.72-.502-1.61-1.202C6.164 8.1 5.539 8.002 5 8.002"
      fontFamily="sans-serif"
      fontWeight={400}
      overflow="visible"
    />
  </svg>
)
