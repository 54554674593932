/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgOtherIncome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgOtherIncome"
    {...props}
  >
    <path
      d="M13.5 10c0 2.761-2.072 3.5-6 3.5s-6-.739-6-3.5c0-3.506 4.625-5.5 4.625-5.5h2.877S13.5 6.494 13.5 10zM9 4.5H6.125L4.75 2s1.371-.5 2.75-.5 2.75.5 2.75.5zM9 4.5h5M7.5 7.375V6M7.5 12.5v-.875"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M5 11.5h3.5a1 1 0 0 0 0-2h-2a1 1 0 0 1 0-2H9"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
