/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBankFees = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgBankFees"
    {...props}
  >
    <path d="M7.502 1a.5.5 0 0 0-.2.041L.606 3.911A1 1 0 0 0 0 4.83V5.5a.5.5 0 0 0 .5.5H3V5H1v-.17l6.5-2.785L14 4.83V5h-2v1h2.5a.5.5 0 0 0 .5-.5v-.67c0-.398-.239-.762-.605-.92L7.697 1.041A.5.5 0 0 0 7.502 1m-.49 4v1H6.5C5.677 6 5 6.677 5 7.5S5.677 9 6.5 9h2c.281 0 .5.219.5.5s-.219.5-.5.5H5v1h2.012v1h1v-1H8.5c.823 0 1.5-.677 1.5-1.5S9.323 8 8.5 8h-2a.493.493 0 0 1-.5-.5c0-.281.219-.5.5-.5H9V6h-.988V5zM1 7v6H0v1h15v-1h-1V7h-1v6H2V7z" />
  </svg>
)
