/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgRetainedEarnings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgRetainedEarnings"
    {...props}
  >
    <path
      d="m14 13.5-13 .019M1.375 9.75l2.063-3.031 2.625 1.625L8.75 4.75l2.75 2.625 2.125-2"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle cx={3.5} cy={2.5} r={0.5} />
    <circle cx={3.5} cy={4.5} r={0.5} />
    <circle cx={3.5} cy={9.5} r={0.5} />
    <circle cx={3.5} cy={11.5} r={0.5} />
    <circle cx={11.5} cy={2.5} r={0.5} />
    <circle cx={11.5} cy={4.5} r={0.5} />
    <circle cx={11.5} cy={9.5} r={0.5} />
    <circle cx={11.5} cy={11.5} r={0.5} />
  </svg>
)
