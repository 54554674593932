/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTraining = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgTraining"
    {...props}
  >
    <path
      d="M4.5 10V8M9 7.5H7.013a1 1 0 0 1-.812-.416l-.912-1.268C5.026 5.5 4.736 5.5 4.5 5.5h-2a1 1 0 0 0-1 1V10"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <circle cx={3.5} cy={3.5} r={1} fill="none" stroke="#000" strokeMiterlimit="10" />
    <circle cx={3.5} cy={12.5} r={1} fill="none" stroke="#000" strokeMiterlimit="10" />
    <circle cx={6.5} cy={12.5} r={1} fill="none" stroke="#000" strokeMiterlimit="10" />
    <circle cx={9.5} cy={12.5} r={1} fill="none" stroke="#000" strokeMiterlimit="10" />
    <circle cx={12.5} cy={12.5} r={1} fill="none" stroke="#000" strokeMiterlimit="10" />
    <path
      d="M10 5.5h2M10 7.5h2M6 2.5h6.5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H6"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
