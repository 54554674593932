/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgOfficeSuppliesSoftware = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgOfficeSuppliesSoftware"
    {...props}
  >
    <path d="M2.5 1C1.676 1 .996 1.676 1 2.5v10c0 .824.676 1.5 1.5 1.5h10c.824 0 1.5-.676 1.5-1.5v-10c0-.824-.676-1.5-1.5-1.5Zm0 1h10c.281 0 .5.219.5.5V4H2V2.5c0-.281.215-.5.5-.5M2 5h11v7.5c0 .281-.219.5-.5.5h-10a.49.49 0 0 1-.5-.5Zm4 1v1h3V6Z" />
  </svg>
)
