/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDomainNames = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgDomainNames"
    {...props}
  >
    <path d="M5.299 5C5.752 3.163 6.656 2 7.5 2s1.748 1.163 2.201 3h1.038C10.222 2.631 8.983 1 7.5 1S4.778 2.631 4.261 5zM9.384 11c-.493 1.242-1.206 2-1.884 2s-1.391-.758-1.884-2H4.539c.614 1.814 1.698 3 2.961 3s2.347-1.186 2.961-3z" />
    <path d="M2.607 5C3.52 3.222 5.368 2 7.5 2s3.98 1.222 4.893 3h1.106c-.982-2.346-3.3-4-5.999-4S2.483 2.654 1.502 5zM11.739 11a5.49 5.49 0 0 1-8.478 0H2.032c1.157 1.801 3.173 3 5.468 3s4.312-1.199 5.468-3zM4.021 10 5 6H4l-.789 4z" />
    <path d="M3.875 10 3 6h-.828l.906 4zM.979 10 0 6h1l.789 4z" />
    <path d="M1.125 10 2 6h.828l-.906 4zM9.021 10 10 6H9l-.789 4z" />
    <path d="M8.875 10 8 6h-.828l.906 4zM5.979 10 5 6h1l.789 4z" />
    <path d="M6.125 10 7 6h.828l-.906 4zM14.021 10 15 6h-1l-.789 4z" />
    <path d="M13.875 10 13 6h-.828l.906 4zM10.979 10 10 6h1l.789 4z" />
    <path d="M11.125 10 12 6h.828l-.906 4z" />
  </svg>
)
