/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgHeadphones01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgHeadphones01Solid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10v6.5a3.5 3.5 0 1 1-7 0v-2a3.5 3.5 0 0 1 5-3.163V12a8 8 0 1 0-16 0v1.337A3.5 3.5 0 0 1 9 16.5v2a3.5 3.5 0 1 1-7 0z"
      clipRule="evenodd"
    />
  </svg>
)
