/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgArrowCircleDownRightSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgArrowCircleDownRightSolid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M4.222 4.222c-4.296 4.296-4.296 11.26 0 15.556s11.26 4.296 15.556 0 4.296-11.26 0-15.556-11.26-4.296-15.556 0m4.242 4.242a1 1 0 0 1 1.415 0l3.95 3.95V9.172a1 1 0 1 1 2 0v5.656a1 1 0 0 1-1 1H9.171a1 1 0 1 1 0-2h3.242l-3.95-3.95a1 1 0 0 1 0-1.414"
      clipRule="evenodd"
    />
  </svg>
)
