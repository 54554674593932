/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFilterFunnel02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgFilterFunnel02Solid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M4.534 2h14.933c.528 0 .992 0 1.355.033.349.031.827.105 1.215.425a2 2 0 0 1 .725 1.621c-.02.503-.284.909-.493 1.19-.218.292-.527.637-.88 1.031l-5.736 6.411c-.08.09-.12.135-.149.17l-.002.002v.003a6 6 0 0 0-.002.225v5.385c.002.138.005.423-.094.69a1.5 1.5 0 0 1-.397.586c-.21.19-.477.294-.605.344l-.035.014-3.428 1.37c-.16.064-.335.135-.49.181a1.5 1.5 0 0 1-.756.05 1.5 1.5 0 0 1-.945-.64 1.5 1.5 0 0 1-.235-.721c-.015-.161-.015-.35-.015-.522v-6.737c0-.12 0-.181-.002-.225v-.003l-.002-.003a6 6 0 0 0-.149-.169L2.641 6.334l-.03-.034c-.352-.394-.661-.739-.88-1.032-.208-.28-.473-.686-.493-1.189a2 2 0 0 1 .725-1.621c.388-.32.867-.394 1.215-.425C3.542 2 4.005 2 4.534 2"
      clipRule="evenodd"
    />
  </svg>
)
