/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBarcodeSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 17 18"
    aria-label="SvgBarcodeSolid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M2.932 3.727H4.09v11.25H2.93zm9.977 0h1.16v11.25h-1.16zm-1.682 0H12v11.25h-.773zm-2.386 0H10v11.25H8.84zm-3.75 0H6.25v11.25H5.09zm1.932 0h.772v11.25h-.772zM0 .852h4.477v1.75H1.773v2.625H0zm17 0v4.375h-1.773V2.602h-2.704V.852zm-17 17v-4.375h1.773v2.625h2.704v1.75zm17 0h-4.477v-1.75h2.704v-2.625H17z"
      clipRule="evenodd"
    />
  </svg>
)
