/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgEdit02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgEdit02Solid"
    {...props}
  >
    <path
      fill="#000"
      d="M21.707 2.293a3.83 3.83 0 0 0-5.414 0L15.13 3.454c-.396.396-.594.594-.668.823a1 1 0 0 0 0 .618c.074.228.272.426.668.822l3.152 3.152c.396.396.594.594.822.668a1 1 0 0 0 .618 0c.229-.074.427-.272.823-.668l1.161-1.162a3.83 3.83 0 0 0 0-5.414M16.869 12.546c.396-.396.594-.594.668-.823a1 1 0 0 0 0-.618c-.074-.228-.272-.426-.668-.822L13.717 7.13c-.396-.396-.594-.594-.822-.668a1 1 0 0 0-.618 0c-.228.074-.426.272-.823.668L3.037 15.55c-.246.245-.462.46-.631.718a3 3 0 0 0-.35.727c-.095.293-.128.597-.166.941l-.384 3.455a1 1 0 0 0 1.104 1.104l3.455-.384c.344-.038.648-.071.94-.166q.389-.125.728-.35c.257-.17.473-.385.718-.63z"
    />
  </svg>
)
