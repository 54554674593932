/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgOfficeExpenses = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgOfficeExpenses"
    {...props}
  >
    <path d="M2.5 1C1.676 1 1 1.676 1 2.5V12h7v3h6V7.5c0-.824-.676-1.5-1.5-1.5H10V2.5C10 1.676 9.324 1 8.5 1Zm0 1h6c.281 0 .5.219.5.5v3.594C8.422 6.3 8 6.852 8 7.5V7H6v1h2v1H6v1h2v1H2V2.5c0-.281.219-.5.5-.5M3 3v1h2V3Zm3 0v1h2V3ZM3 5v1h2V5Zm3 0v1h2V5ZM3 7v1h2V7Zm6.5 0h3c.281 0 .5.219.5.5V14H9V7.5c0-.281.219-.5.5-.5m.5 1v1h2V8ZM3 9v1h2V9Zm7 1v1h2v-1Zm0 2v1h2v-1Z" />
  </svg>
)
