/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTech = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgTech"
    {...props}
  >
    <path
      d="M10.5 11.5h-6a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path d="M4 1h1v1H4zM6 1h1v1H6zM8 1h1v1H8zM10 1h1v1h-1zM4 13h1v1H4zM6 13h1v1H6zM8 13h1v1H8zM10 13h1v1h-1zM1 4h1v1H1zM1 6h1v1H1zM1 8h1v1H1zM1 10h1v1H1zM13 4h1v1h-1zM13 6h1v1h-1zM13 8h1v1h-1zM13 10h1v1h-1z" />
    <circle cx={5.5} cy={5.5} r={0.5} />
    <circle cx={9.5} cy={9.5} r={0.5} />
  </svg>
)
