/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFilterFunnel01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgFilterFunnel01"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2 4.6c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437C2.76 3 3.04 3 3.6 3h16.8c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C22 3.76 22 4.04 22 4.6v.67c0 .268 0 .403-.033.528q-.044.166-.142.31c-.072.106-.174.194-.377.37l-6.396 5.544c-.203.175-.305.264-.378.37q-.097.144-.141.31c-.033.125-.033.26-.033.529v5.227c0 .196 0 .294-.032.378a.5.5 0 0 1-.132.195c-.067.061-.157.097-.339.17l-3.4 1.36c-.367.147-.551.22-.699.19a.5.5 0 0 1-.315-.213c-.083-.126-.083-.324-.083-.72v-6.587c0-.27 0-.404-.033-.529a1 1 0 0 0-.141-.31c-.073-.106-.175-.195-.378-.37L2.552 6.477c-.203-.176-.305-.264-.378-.37a1 1 0 0 1-.141-.31C2 5.673 2 5.538 2 5.269z"
    />
  </svg>
)
