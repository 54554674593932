/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgOtherCurrentLiabilities = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgOtherCurrentLiabilities"
    {...props}
  >
    <path
      d="M11.591 4.242a1 1 0 0 0-.966-.742H4.268a1 1 0 0 0-.966.742l-1.633 6.116a5 5 0 0 0-.169 1.289v.845a1 1 0 0 0 1 1l10 .008a1 1 0 0 0 1-1v-.845a5 5 0 0 0-.169-1.288zM8.5 3.5v-1a1 1 0 0 0-2 0v1M7.512 6.4V5M7.512 12v-1.4"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
    <path
      d="M5 10.5h3.5a1 1 0 0 0 0-2h-2a1 1 0 0 1 0-2H9"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
    />
  </svg>
)
