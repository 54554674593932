/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTrophy01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgTrophy01Solid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M7.444 1h-.09c-.313-.001-.698-.003-1.038.12a2 2 0 0 0-1.195 1.196c-.08.22-.108.458-.117.684h-1.53c-.21 0-.415 0-.59.012-.19.013-.415.043-.65.14a2 2 0 0 0-1.082 1.083c-.097.234-.127.46-.14.65C1 5.059 1 5.263 1 5.474v.664c0 .795 0 1.386.136 1.897a4 4 0 0 0 2.829 2.829c.38.102.805.128 1.324.134A7.01 7.01 0 0 0 11 15.93V17h-.444A4.556 4.556 0 0 0 6 21.556C6 22.353 6.647 23 7.444 23h9.112c.797 0 1.444-.647 1.444-1.444A4.556 4.556 0 0 0 13.444 17H13v-1.07a7.01 7.01 0 0 0 5.71-4.932c.52-.006.945-.032 1.325-.134a4 4 0 0 0 2.829-2.829C23 7.524 23 6.933 23 6.138v-.664c0-.21 0-.415-.012-.59a2 2 0 0 0-.14-.65 2 2 0 0 0-1.083-1.082 2 2 0 0 0-.65-.14C20.941 3 20.736 3 20.526 3h-1.53a2.3 2.3 0 0 0-.117-.684 2 2 0 0 0-1.195-1.195C17.344.997 16.96.999 16.645 1h-.09zM5 5.8c0-.28 0-.42-.054-.527a.5.5 0 0 0-.219-.218C4.62 5 4.48 5 4.2 5h-.456c-.265 0-.397 0-.5.051a.5.5 0 0 0-.218.206c-.056.101-.063.229-.076.485-.032.603-.028 1.228.118 1.776a2 2 0 0 0 1.414 1.414c.12.032.26.049.518.058zm14 .003c0-.28 0-.421.055-.528a.5.5 0 0 1 .22-.22c.107-.053.247-.053.528-.052l.448.002c.264.001.395.002.499.053.09.044.167.118.216.205.056.101.063.229.076.484.032.604.032 1.241-.11 1.77a2 2 0 0 1-1.414 1.415c-.12.032-.26.049-.518.058z"
      clipRule="evenodd"
    />
  </svg>
)
