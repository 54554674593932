/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="2.5 -3.002 22 22.004"
    aria-label="SvgGoogle"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    <path d="M13.958 6.08h10.36q.17.952.182 1.918a10.5 10.5 0 0 1-.182 1.918c-1.059 5.982-6.762 9.974-12.74 8.916S1.612 12.066 2.67 6.083C3.727.102 9.431-3.89 15.409-2.832a11 11 0 0 1 5.075 2.343l-2.703 2.733A7.07 7.07 0 0 0 13.479.806C9.51.802 6.288 4.018 6.283 7.99s3.209 7.196 7.179 7.201a7.19 7.19 0 0 0 6.936-5.275h-6.44z" />
  </svg>
)
