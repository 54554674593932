/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDetectedReview = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 32 32"
    aria-label="SvgDetectedReview"
    {...props}
  >
    <path
      fill="#000"
      d="m3.25 6.5.392.784c.133.266.2.399.288.514a1.5 1.5 0 0 0 .272.272c.115.089.248.155.514.288l.784.392-.784.392c-.266.133-.399.2-.514.288a1.5 1.5 0 0 0-.272.272c-.089.115-.155.248-.288.514L3.25 11l-.392-.784c-.133-.266-.2-.399-.288-.514a1.5 1.5 0 0 0-.272-.272c-.115-.089-.248-.155-.514-.288L1 8.75l.784-.392c.266-.133.399-.2.514-.288a1.5 1.5 0 0 0 .272-.272c.089-.115.155-.248.288-.514zM7.5 1l.59 1.532c.14.367.21.55.32.704a1.5 1.5 0 0 0 .354.354c.154.11.337.18.704.32L11 4.5l-1.532.59c-.367.14-.55.21-.704.32a1.5 1.5 0 0 0-.354.354c-.11.154-.18.337-.32.704L7.5 8l-.59-1.532c-.14-.367-.21-.55-.32-.704a1.5 1.5 0 0 0-.354-.354c-.154-.11-.337-.18-.704-.32L4 4.5l1.532-.59c.367-.14.55-.21.704-.32a1.5 1.5 0 0 0 .354-.354c.11-.154.18-.337.32-.704z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.42 16.713c-.136-.216-.204-.323-.242-.49a1.2 1.2 0 0 1 0-.446c.038-.167.106-.274.242-.49C7.546 13.505 10.895 9 16 9s8.455 4.505 9.58 6.287c.137.215.205.323.243.49.029.125.029.322 0 .446-.038.167-.106.274-.242.49C24.455 18.495 21.105 23 16 23c-5.106 0-8.455-4.505-9.58-6.287"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16 19a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
    />
  </svg>
)
