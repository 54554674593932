/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgShield01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgShield01Solid"
    {...props}
  >
    <path
      fill="#000"
      d="M12.286 1.088a2 2 0 0 0-.572 0c-.218.032-.422.109-.583.17l-.044.016-5.492 2.06c-.618.23-1.16.433-1.578.801a3 3 0 0 0-.82 1.184c-.199.52-.198 1.1-.197 1.759V12c0 2.827 1.538 5.183 3.2 6.914 1.672 1.743 3.586 2.974 4.598 3.564l.041.024c.185.109.425.25.742.318.26.055.58.055.839 0 .317-.068.556-.209.741-.317l.041-.024c1.012-.591 2.926-1.822 4.6-3.565C19.462 17.184 21 14.826 21 12V7.078c.001-.66.002-1.239-.196-1.76a3 3 0 0 0-.82-1.183c-.418-.368-.96-.57-1.578-.801l-5.493-2.06-.043-.016a2.8 2.8 0 0 0-.584-.17"
    />
  </svg>
)
