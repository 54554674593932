/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMessageCircle01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgMessageCircle01"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 11.5a8.5 8.5 0 0 1-11.555 7.935c-.174-.067-.26-.1-.33-.116a1 1 0 0 0-.186-.025c-.07-.003-.148.005-.303.021l-5.12.53c-.49.05-.733.075-.877-.013a.5.5 0 0 1-.234-.35c-.026-.166.09-.382.324-.814l1.636-3.027c.134-.25.202-.374.232-.494a.9.9 0 0 0 .028-.326c-.01-.123-.064-.283-.172-.604A8.5 8.5 0 1 1 21 11.5"
    />
  </svg>
)
