/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMessageAlertCircleSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgMessageAlertCircleSolid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M12.5 2a9.5 9.5 0 0 0-9.004 12.536c.056.168.09.267.111.34l.007.025-.003.008a8 8 0 0 1-.136.256l-1.652 3.058c-.102.19-.209.387-.282.559-.073.17-.192.484-.133.856a1.5 1.5 0 0 0 .7 1.048c.321.196.656.206.842.204a7 7 0 0 0 .624-.047l5.155-.533.158-.016h.004l.012.004c.04.014.092.035.183.07A9.5 9.5 0 0 0 22 11.5 9.5 9.5 0 0 0 12.5 2m1 5.5a1 1 0 1 0-2 0V11a1 1 0 1 0 2 0zm-1 6a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2z"
      clipRule="evenodd"
    />
  </svg>
)
