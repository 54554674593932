/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMagicWand01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    stroke="#000"
    viewBox="0 0 24 24"
    aria-label="SvgMagicWand01"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m13 14-3-3m5.01-7.5V2m3.94 3.06L20.01 4m-1.06 9 1.06 1.06m-9-9L9.95 4m10.56 5h1.5M6.131 20.869l9.238-9.238c.396-.396.594-.594.668-.822a1 1 0 0 0 0-.618c-.074-.228-.272-.426-.668-.822l-.738-.738c-.396-.396-.594-.594-.822-.668a1 1 0 0 0-.618 0c-.229.074-.427.272-.823.668L3.131 17.87c-.396.396-.594.594-.668.822a1 1 0 0 0 0 .618c.074.228.272.426.668.822l.737.738c.396.396.594.594.823.668a1 1 0 0 0 .618 0c.228-.074.426-.272.822-.668"
    />
  </svg>
)
