/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTransactionDashboardCurrentPeriod = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 17 17"
    aria-label="SvgTransactionDashboardCurrentPeriod"
    {...props}
  >
    <path
      fill="#00A69C"
      d="M14.32 3.577a.84.84 0 1 0-.842-1.457.84.84 0 0 0 .841 1.457M16.019 6.127a.84.84 0 1 0-.84-1.457.84.84 0 0 0 .84 1.457M12.077 1.57A.84.84 0 1 0 10.62.729a.84.84 0 0 0 1.457.84"
    />
    <path
      fill="#00A69C"
      d="M15.355 8.5A6.855 6.855 0 1 1 8.5 1.645h.823V0H8.5A8.5 8.5 0 1 0 17 8.5v-.823h-1.645z"
    />
  </svg>
)
