/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgArrowCircleUpRightSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgArrowCircleUpRightSolid"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M4.222 19.778c4.296 4.296 11.26 4.296 15.556 0s4.296-11.26 0-15.556-11.26-4.296-15.556 0-4.296 11.26 0 15.556m4.242-4.243a1 1 0 0 1 0-1.414l3.95-3.95H9.172a1 1 0 0 1 0-2h5.656a1 1 0 0 1 1 1v5.657a1 1 0 1 1-2 0v-3.242l-3.95 3.95a1 1 0 0 1-1.414 0"
      clipRule="evenodd"
    />
  </svg>
)
