/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMail01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    aria-label="SvgMail01Solid"
    {...props}
  >
    <g fill="#000">
      <path d="M2.134 8.181c-.3-.214-.45-.32-.581-.335a.51.51 0 0 0-.488.251C1 8.212 1 8.394 1 8.76v6.482c0 .805 0 1.47.044 2.01.046.563.145 1.08.392 1.565a4 4 0 0 0 1.748 1.748c.485.247 1.002.346 1.564.392C5.29 21 5.954 21 6.758 21h10.483c.805 0 1.47 0 2.01-.044.563-.046 1.08-.145 1.565-.392a4 4 0 0 0 1.748-1.748c.247-.485.346-1.002.392-1.564.044-.541.044-1.206.044-2.01V8.758c0-.179 0-.268-.016-.33a.503.503 0 0 0-.655-.344c-.06.022-.135.074-.284.177l-7.742 5.36c-.549.381-1.03.716-1.578.849a3 3 0 0 1-1.456-.011c-.546-.14-1.023-.483-1.566-.872z" />
      <path d="M22.133 5.769c.184-.128.277-.192.333-.295a.56.56 0 0 0 .06-.297c-.013-.116-.063-.193-.162-.347a4.03 4.03 0 0 0-1.548-1.394c-.485-.247-1.002-.346-1.564-.392C18.71 3 18.046 3 17.242 3H6.758c-.805 0-1.47 0-2.01.044-.563.046-1.08.145-1.565.392a4 4 0 0 0-1.41 1.197c-.115.158-.173.237-.192.356a.56.56 0 0 0 .05.308c.055.107.15.176.343.313l8.775 6.268c.727.52.882.61 1.018.645a1 1 0 0 0 .486.004c.137-.033.292-.121 1.027-.63z" />
    </g>
  </svg>
)
