/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCulture = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 16 16"
    aria-label="SvgCulture"
    {...props}
  >
    <path
      d="M.5 10.346S3.81 11.5 8.045 11.5s7.455-1.154 7.455-1.154M2.896 8.787C4.11 9.084 5.692 9.5 8 9.5s3.848-.42 5.048-.721"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
    <path
      d="M2.5 11.072V7.538C2.5 5.317 4.48 3.5 6.9 3.5h2.2c2.42 0 4.4 1.817 4.4 4.038v3.534"
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    />
  </svg>
)
