/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSearchVendor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="-6 -6 31 31"
    aria-label="SvgSearchVendor"
    {...props}
  >
    <path
      fill="#00A69C"
      fillOpacity={0.3}
      d="m17.411 3.359-5.8.247a1.85 1.85 0 0 0-1.361.606l-8.156 8.144c-.823.804-.79 2.153.014 2.976L7.749 21c.804.823 2.153.79 2.976-.013l8.155-8.145c.365-.41.545-.856.65-1.411l.066-5.933c.042-1.239-.929-2.19-2.185-2.14m-1.44 2.848c-.572-.012-1.058-.487-1.138-1.078.012-.573.487-1.058 1.078-1.139.573.013 1.059.488 1.139 1.079.063.683-.413 1.168-1.078 1.138"
    />
    <path
      fill="url(#search_vendor_svg__a)"
      fillOpacity={0.8}
      d="m16.381 1.932-5.842.282c-.565 0-1.036.283-1.413.566L.928 10.884c-.848.848-.848 2.167 0 2.92l5.654 5.654c.848.848 2.167.848 2.92 0l8.105-8.103c.47-.377.66-.848.66-1.414l.188-5.936c.094-1.131-.943-2.167-2.073-2.073m-.47 3.863c-.566 0-1.131-.471-1.131-1.13 0-.66.47-1.037 1.13-1.037.566 0 1.131.471 1.131 1.13 0 .66-.565 1.037-1.13 1.037"
    />
    <g filter="url(#search_vendor_svg__b)">
      <path
        fill="#00A69C"
        d="m16.381 1.756-5.842.283c-.565 0-1.036.282-1.413.565L.928 10.708c-.848.848-.848 2.167 0 2.92l5.654 5.654c.848.849 2.167.849 2.92 0l8.105-8.103c.47-.377.66-.848.66-1.414l.188-5.936c.094-1.13-.943-2.167-2.073-2.073m-.47 3.863c-.566 0-1.131-.47-1.131-1.13s.47-1.037 1.13-1.037c.566 0 1.131.471 1.131 1.13 0 .66-.565 1.037-1.13 1.037"
      />
    </g>
    <defs>
      <linearGradient
        id="search_vendor_svg__a"
        x1={12.837}
        x2={7.317}
        y1={14.528}
        y2={1.844}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AF9FF" stopOpacity={0.69} />
        <stop offset={0.865} stopColor="#D4FEFD" />
      </linearGradient>
      <filter
        id="search_vendor_svg__b"
        width={20.169}
        height={20.168}
        x={-0.708}
        y={0.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18287_216850" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18287_216850" result="shape" />
      </filter>
    </defs>
  </svg>
)
